// import {useSelector}    from 'react-redux';
import React from 'react';
import {makePropGetter,useAsyncDebounce,useGetLatest} from 'react-table';
import { publish } from '../utils/socket';


let user;

const useTableState = (hooks)=>{

    // const {PageOpenID} = useSelector(state=>state.userLogin);

    // user = PageOpenID;
    hooks.getEditableProps = [defaultGetEditableProps];
    hooks.useInstance.push(useInstance); 
    hooks.prepareRow.push(prepareRow); //prepareRow is correct place to link todo store 
 };

 //check the status, and show different UI
const defaultGetEditableProps = (props,{cell}) => [
    props,
    {
      onClick: (e) => {
        // if (['SOURCE','AUTOLINE','BOM','OFFER','PHOTO'].includes(cell.column.id))
        // {
        //   cell.row.setState((state)=>{
        //     return { ...state,expandName:cell.column.id};
        //   });
        // }
      },
      onDoubleClick : (e)=>{
          // const {tableName,to} = cell.row.original;
          // // alert(tableName);
          // // task receiver, not allowed to modify the column
          // if(user === to) return;
          // // return; // not allowed to modify...

          // if(tableName === 'eparts_app.tb_to_buy')
          // {
          //     if(cell.column.id === 'qty_to_buy')
          //     {
          //         //render the UpdateColumnModal
          //         cell.setState(state=>{
          //             return {
          //                      ...state,
          //                      UpdateCell:!state?.UpdateCell,
          //                      action:{type:'TASK_UPDATE',column:'qty_change_to'}
          //             };
          //         });
          //     }

          //     if(cell.column.id === 'price_old')
          //     {
          //         cell.setState(state=>{
          //           return {
          //                   ...state,
          //                   UpdateCell:!state?.UpdateCell,
          //                   action:{type:'TASK_UPDATE',column:'price_new'}
          //           };
          //         });
          //     }
          // }

      }
    }
];
  
function useInstance(instance) 
{
     const {state:{ globalFilter,pageIndex,expanded,filters}} = instance;

     const handlePublish =  React.useCallback((obj)=>{
        
        sessionStorage.setItem(
          'status',
          JSON.stringify({globalFilter,pageIndex,expanded,filters})
        );

        publish(obj);
        
    },[globalFilter,pageIndex,expanded,filters]);

    Object.assign(instance,{handlePublish});
}

function prepareRow(row,{ instance: { getHooks}}) { 
    // let dispatch = useDispatch();
    if(row){
        row.cells.forEach(cell=>{
            //prepare the cellProps
            cell.getCellProps = makePropGetter(
              getHooks().getEditableProps,
              {
                cell
              }
            );
        });
    }
    
};

export default useTableState;