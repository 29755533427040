import { io } from "socket.io-client";
// import { USER_LOGIN_SOCKET } from "../redux/constants/userConstants";
// import {useDispatch} from 'react-redux';
import * as autolineConstants   from '../redux/constants/autolineConstants';
import * as soureConstants      from '../redux/constants/sourceConstants';
import * as forecastConstants   from '../redux/constants/forecastConstants';
import * as todoConstants       from '../redux/constants/todoConstants';
import * as wepayConstants      from '../redux/constants/wepayConstants';
import * as uploadConstants     from '../redux/constants/uploadConstants';

const URL =process.env.NODE_ENV === 'production'
?'https://service.eparts.cn'
:'http://localhost:4000';


// console.log('url',URL); 
const socket = io.connect(URL,{
   path:'/socket.io',
   query:{
      openID:sessionStorage.getItem('PageOpenID') ? sessionStorage.getItem('PageOpenID'):''
   }
});

//login to server 
socket.on('connect',()=>{
    console.log('socket connected:'+socket.id);
    //establish socket
   //  socket.emit(USER_LOGIN_SOCKET,{type:USER_LOGIN_SOCKET,payload:{message:'NEED socket id'}});   
});

socket.on('disconnect',(reason)=>{
 //  console.log('disconnect reaseon',reason);
  if (reason === "io server disconnect") {
     // the disconnection was initiated by the server, you need to reconnect manually
     socket.connect();
   }
});
//publish the action to server
const publish = (action)=>{
      const types = [
         'todoLogin',
         'DEBUG_LOGIN',
         'USER_LOGIN_REQUEST',
         'USER_LOGIN_UPDATE',
         'USER_LOGIN_SUCCESS',
         'USER_LOGIN_SOCKET',
         'USER_LOGIN_SWITCH',
         'USER_LOGIN_FAIL',
         'USER_LOGOUT',
         'JOB_ROW_REQUEST',
         'JOB_ROW_UPDATE',
         'JOB_ROW_REFUSE',
         'JOB_ROW_COMPLETE',
         'TICKET_ROW_REQUEST',
         'TICKET_ROW_UPDATE',
         'TICKET_ROW_REFUSE',
         'TICKET_ROW_COMPLETE',
         'PAYMENT_ROW_REQUEST',
         'PAYMENT_ROW_UPDATE',
         'PAYMENT_ROW_REFUSE',
         'PAYMENT_ROW_COMPLETE',
         'BOM_LIST',
         'MESSAGE_UPDATE',
         'TASK_LOGIN',
         'TASK_UPDATE',
         'TASK_CONFIRM',
         ...Object.values(autolineConstants),
         ...Object.values(soureConstants),
         ...Object.values(forecastConstants),
         ...Object.values(todoConstants),
         ...Object.values(wepayConstants),
         ...Object.values(uploadConstants)
         
      ];
      return new Promise((resolve,reject)=>{
            
            try {
               // console.log('check action',action);
               if(!types.includes(action.type) || !action?.type){
                   throw new Error('FAIL TO VALIDATE ACTION TYPE');
               }
               socket.emit(action.type,action);
               resolve('SUCCESS');

            } catch(error){ 

               reject(`ERROR:${error.message}`);
               // return reject({type:"ACTION_ERROR",payload:{messsage:error.message}});
            }

     });
}

const upload = (obj)=>{
     
     return new Promise((resolve,reject)=>{
          try {
            let flag = false;
            socket.emit('upload',obj);         
            // let flag = false;
            socket.once('response',(res)=>{
                 flag = true;
                 return resolve(res.trim());
            });

            setTimeout(()=>{
               if(!flag){
                  return reject('NETWORK TIMEOUT');
               } 
            },1000 * 60 * 3);
            
          } catch (error){
              return reject(error.message);
          }
         
     });
  
     
};


export {socket,publish,upload};
