import {Navbar,Container,Nav,NavDropdown, Button, Badge } from 'react-bootstrap';
// import { LinkContainer } from 'react-router-bootstrap';
import { useLocation,useNavigate } from 'react-router-dom';
import LanguageContext from '../contexts/contexts';
import React,{ useContext} from 'react';
import {useSelector,useDispatch} from 'react-redux';
import logo from '../images/eparts.png';
import LanguageBtn from './LanguageBtn';
import { USER_LOGOUT } from '../redux/constants/userConstants';
import ShowContracts from '../modals/ShowContracts';
import { publish } from '../utils/socket';
// import RenderPayment from './RenderPayment';
import { TODO_REMOVE } from '../redux/constants/todoConstants';

const Token = ()=>{
    const {token} = useSelector(state=>state.userLogin);
    const {userInfo} = useSelector(state=>state.userLogin);
    
    return (
        <>
          {!userInfo?.PID && (<span className='btn btn-sm btn-outline-danger rounded ml-1'>ID:{token}</span>)}
        </>
    );
}


const Header = ()=>{

    const {pathname} = useLocation();
    const navigate = useNavigate();
   
    const handleAdmin = ()=>{
         let path = pathname === '/admin' ? '/' :'/admin';
         navigate(path);
    }
    const [show,setShow] = React.useState(false);
    const {language} = useContext(LanguageContext);//{languagte:'cn'};// useContext(LanguageContext) ||'cn';

    const {PageOpenID,userInfo} = useSelector(state=>state.userLogin);//extract the token number

    const {data}    = useSelector(state=>state.message);
    //PageOpenID, it is the user which in current page...
    const messages = data.length > 0 
         ? data.filter(m=>[m?.from,m?.to].includes(PageOpenID)) 
         : [];

    const dispatch = useDispatch();

    let hide = false;

    const profile = language === 'cn' ? '账户信息':'PROFILE';

    let logoutHandler = ()=>{
        try {
            publish({type:'USER_LOGOUT',payload:{originalOpenID:userInfo?.originalOpenID}});
            dispatch({type:USER_LOGOUT});
            dispatch({type:TODO_REMOVE});

        } catch (error){}
    };

    const handleClose = (e)=>{
          setShow(false);
    };

    const getCatalog = ()=>{

        if(!userInfo?.PID) return;
        
        if(window.confirm('确认需要拉取目录清单?'))
        {
             publish({
                type:'TODO_FETCH',
                payload:{
                    type:'GET_SOURCE_LIST',
                    ...userInfo
                }
             });
        }
  };
  

     return (
     <header id ='header'>
        {/* bg='dark' */}
        <section style = {{height:'15vh'}}>
            <Navbar  variant='light' expand='lg' collapseOnSelect className='epartsHeader py-3 px-3'>
                <Container>
                    {/* <LinkContainer >    </LinkContainer>  width='114' height='53'*/}
                    <Navbar.Brand className='mr-3'><img src = {logo} width='105vw' alt = 'eparts logo' /></Navbar.Brand>
                
                    {/* color:'#C4261D' */}
                    {/* <p className ='mt-4 mr-3 slogan' style = {{fontSize:'120%'}}>
                        {
                            language==='en'
                            ?'CATALOGING SERVICE'
                            :'专业目录式销售企业'
                        }
                    </p> */}
                    <p className ='mt-4 mr-3 slogan' style = {{fontSize:'120%'}}>
                        {
                            language==='en'
                            ?'SPARE PARTS DISCOUNT SERVICE'
                            :'汽配折扣服务'
                        }
                    </p>
                    {/* <p className ='mt-4 mr-3 slogan' style = {{fontSize:'120%'}}>
                        {
                            language==='en'
                            ?'DATA-DRIVEN MANUFACTURERS'
                            :'数据驱动式生产厂商'
                        }
                    </p> */}
                    <LanguageBtn />
                    {/* <Token /> */}

                    {hide && (<Button variant='outline-danger' size='sm' className ='ml-5 mr-5 rounded hideLogout' onClick = {()=>alert('hi')}><i className="fa fa-sign-out mr-2" aria-hidden="true"></i>{language==='en'?'LOGOUT':'退出'}</Button>)}
                    {/* {!userInfo && (<Button variant ='outline-light' size='sm' className='ml-auto mr-5 rounded'>&nbsp;</Button>)} */}
                    {!hide && (<Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="ml-auto mr-5 " style={{fontSize:'100%'}}>
                        {/* {userInfo && (<Button variant='outline-danger' size='sm' className ='ml-auto  rounded' onClick = {logoutHandler}><i className="fa fa-sign-out mr-2" aria-hidden="true"></i>LOGOUT</Button>)} */}
                            
                            
                    
                            {!hide && (
                            <NavDropdown  title = {
                            <>
                                {
                                    userInfo?.PID && userInfo.PID.length >0 ?
                                    (
                                        <button className='btn btn-sm btn-outline-danger p-2 rounded'>
                                        {
                                            language === 'cn' ? '伙伴编号:':'PARTNER ID:'
                                        } 
                                            
                                        {
                                            userInfo.PID
                                        }
                                        </button>
                                    )
                                    :  
                                    (
                                    <button className='btn btn-sm btn-outline-danger p-2 rounded'>{
                                        profile
                                    }</button>
                                    )
        
                                }
                            

                            </>} id = 'username'>
                                {/* <LinkContainer to = '/profile'>
                                    <NavDropdown.Item><i className="fa fa-info mr-2" aria-hidden="true"></i>个人资料</NavDropdown.Item>
                                </LinkContainer> */}
                            
                                {userInfo?.role_type === 'STAFF' && (
                                    <>
                                    <NavDropdown.Item onClick = {()=>setShow(!show)} >
                                        <i className="fa fa-file-o mr-2" aria-hidden="true"></i>{language==='en'?'CONTRACTS':'合同'}
                                    </NavDropdown.Item>
                                    {show && (<ShowContracts rowData = {userInfo} handle = {handleClose} />)}
                                    </>
                                    
                                )}
                                {
                                // userInfo?.role_type === 'VENDOR' && (
                                //     <NavDropdown.Item onClick = {getCatalog} ><i className="fa fa-list mr-2" aria-hidden="true"></i>{language==='en'?'CATALOG':'目录清单'}</NavDropdown.Item>
                                // )
                                }
                                
                                <NavDropdown.Item onClick = {logoutHandler} ><i className="fa fa-sign-out mr-2" aria-hidden="true"></i>{language==='en'?'LOGOUT':'退出'}</NavDropdown.Item>
                            </NavDropdown>
                            ) }
                        </Nav>
                    </Navbar.Collapse>)}
                </Container>
            </Navbar>
           {/* <RenderPayment /> */}
        </section>
     </header>
   );
}

// export default Object.assign(Header,{Alert});
export default Header;