import { WEPAY_FETCH, WEPAY_REQUEST } from "../constants/wepayConstants";

//{todo._id,todo.JSAPI}
const wepayReducer = (state={payRow:{}},action)=>{
    
    switch(action.type)
    {
       case WEPAY_FETCH:
           return {...state,payRow:action.payload};
           
       case WEPAY_REQUEST:
           return {...state,payRow:{...state.payRow,JSAPI:action.payload}};
       default:
           return state;
    } 
};

export default wepayReducer;