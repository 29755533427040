import axios from 'axios';
import {useEffect,useContext,useState} from 'react';
import { useSelector} from 'react-redux';
import LanguageContext from '../contexts/contexts';
import Loader from './Loader';
const BarcodeLogin = ({bu})=>{

    const {language} = useContext(LanguageContext);
    
    const {socketID,userInfo} = useSelector(state=>state.userLogin);

    const [barcodeUrl,setBarcodeUrl] = useState('');
    const [loading,setLoading]       = useState(false);

    let loginStr = language ==='en' ? 'F2B LOGIN':'F2B用户登录';
    let scanStr = language === 'en' ?'WECHAT SCAN':'微信扫码';
    

    useEffect(()=>{
        (async()=>{

            try {
                setLoading(true);
                setBarcodeUrl(false);

                let tempStr = '';
   
                // let socketID = sessionStorage.getItem('socketID') ? sessionStorage.getItem('socketID'):'';
                if(!socketID || socketID.length !== 20){
                    throw new Error('NO SOCKET ID FOUND');
                }
                //collaborate barcode
                const endPoint = "https://www.eparts.cn/wechat/get_barcode/COLLABORATE::"+socketID+"::"+language+"::"+bu;
                // console.log(endPoint);

                const {data:img} = await axios.get(endPoint);
                
                tempStr  = `<div className='my-5' style='font-size:105%;color:#C4261D !important;margin-bottom:20px !important;margin-top:40px !important'><b className='text-danger'>${loginStr}</b></div>`; 
                tempStr  += img.trim(); //attach the image data into the HTML
                tempStr  += `<div className='mt-2'>${scanStr}</div>`;
                setBarcodeUrl(tempStr);
                setLoading(false);
              
                return ()=>{};
            } catch(error){
                console.log(error.message);
            }
            
        })(); 
    },[language,socketID,bu,loginStr,scanStr]);

    return (
      <>
       <div className='mx-auto'>
        {loading && (<Loader />)}
        
        <>

            {userInfo?.PID && ( <div className='form-group text-danger'>
            <label>伙伴编号:</label>
            <label>{userInfo.PID}</label>
            </div>) 
            }
            {barcodeUrl && (<div className='text-center' dangerouslySetInnerHTML={{ __html: barcodeUrl }} />) }
        </>
    
       </div>
      </>
    );
}
export default BarcodeLogin;