import axios from 'axios';

import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Loader from './Loader';
import ShowComments from '../modals/ShowComments';

// Create an editable cell renderer
const EditableCell = ({
  value: initialValue,
  row: { index,original },
  column: { id },
  initialState:{tableName},
  updateMyData, // This is a custom function that we supplied to our table instance
}) => {
    const {uuid} = original; // 
    // console.log(tableName);
    // We need to keep and update the state of the cell normally
    const [value, setValue] = React.useState(initialValue);
    const [edit,setEdit] = React.useState(false);
    const [loading,setLoading] = React.useState(false);

    const [tempShow,setTempShow] = React.useState(true);

    const [show,setShow] = React.useState(false);

    const handleClose = (e)=>{
          setShow(false);
    }

    const onChange = e => {
        setValue(e.target.value);
    }

    // We'll only update the external data when the input is blurred
    const onBlur = () => {

        (async()=>{

            try {

                if(!parseInt(value) && !value) return; 

                setLoading(true);
        
                //http_request
                const config = {
                    headers : {
                       'Content-Type':'application/json'
                       // Authorization: `Bearer ${ userInfo.token }`
                    }
                };
                
                //EDIT => update the record
                const URL =process.env.NODE_ENV === 'production'
                ?'https://service.eparts.cn/api/update'
                :'http://localhost:4000/api/update'; 

                //business
                const column = isNaN(parseFloat(value))?'comments':'qty';

                const {data}  = await axios.post(URL,{tableName,column,value,uuid},config);
                console.log('response',data);

                if(/ERROR/.test(data.msg)){
                    throw new Error(data.msg);
                }
                //update the data
                updateMyData(index, id, value);

                setLoading(false); 
                setEdit(!edit);

            } catch(error){
                 console.log(error.message);
            }
           

        })();
      
    }

    const hide = ()=>{
          setTempShow(!tempShow);
          setShow(false);
    }

    // If the initialValue is changed external, sync it up with our state
    React.useEffect(() => {
        setValue(initialValue);
    }, [initialValue])

    return(
        <> 
           {show && (<ShowComments 
                      rowData = {original} 
                      handle = {handleClose} 
                      hide = {hide}
                      
                      />)}
           <Row>
                <Col xs = {12} md = {4}>
                 <span className='text-danger mr-2' 
                     onDoubleClick={()=>setEdit(!edit)}
                 >
                    <b className='text-dark' 
                       onDoubleClick = {()=>setTempShow(!tempShow)}
                     >{'FY24'}:</b>{value ? value :0}
                </span>
                </Col>
                <Col xs = {12} md = {6}>
                
                  {
                   original.comments.length > 1 && original.qty == '0' && tempShow
                   ?(<span className='badge badge-danger p-1' onClick = {()=>setShow(!show)}>...</span>)
                   :(
                    !initialValue || edit ?(<input 
                            type='text' 
                            value={value} 
                            onChange={onChange} 
                            onBlur={onBlur}
                            placeholder='forecast'
                            style={{width:'98%'}}
                        />) : null
                    )
                  }
                </Col>
                <Col xs = {12} md = {2}>
                    {loading ? <Loader size={20} /> :null}
                </Col>
           </Row>
        </>
    )
}
export default EditableCell;