import React from 'react';
import ShowDrawing from "../modals/ShowDrawing";
//render the drawing
const RenderDrawing = (props)=>{
   const {row:{original},state:{tableName}} = props;
  //  console.log(original,tableName);
   const [dwg,setDwg] = React.useState(false);
   
   //close the modal
   const handleClose = ()=>{
       setDwg(false);
   };
//    console.log(props);
   return (
        <>
            {
            /^03\./.test(original.ref_no) 
            ?  (
                <span className ='btn btn-sm btn-outline-danger rounded' onClick ={()=>setDwg(true)}>
                <i className="fa fa-file-pdf-o" aria-hidden="true"></i>
                </span>
            )
            : (
                <span className ='btn btn-sm btn-outline-secondary rounded' onClick ={()=>alert('no drawing')}>
                  <i className="fa fa-file-pdf-o" aria-hidden="true"></i>
                </span>
              )
            }
            {dwg && (<ShowDrawing rowData ={original} handle = {handleClose} />)}
        </>   
   );
}

export default RenderDrawing;