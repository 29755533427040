
import { AUTOLINE_FETCH, AUTOLINE_ITEM_RESEARCH, AUTOLINE_SOURCE_FETCH, AUTOLINE_TO_SOURCE } from "../constants/autolineConstants";
const autolineReducer = (
     state={
          data:[],
          bom:[],
          toList:[],
          toSource:[],
          itemResearch:[], //get the photo of the oe brand photo
          errMsg:[]
    },action)=>{
     switch(action.type)
     {
        case AUTOLINE_FETCH:
            return {...state,data:action.payload};
        case AUTOLINE_SOURCE_FETCH:
            const uuids = action.payload.reduce((acc,item)=>{
                  return [...acc,item.uuid];
            },[]);
            const filters = state.toSource.filter(x=>!uuids.includes(x.uuid));
            return {...state,toSource:[...filters,...action.payload]};
        case AUTOLINE_ITEM_RESEARCH:
            //attach the new item
            return {  ...state,
                      itemResearch:[
                        ...state.itemResearch.filter(x=>x._id !== action.payload._id)
                        ,action.payload
                      ]
                   };

        case AUTOLINE_TO_SOURCE:

            return {
                 ...state,
                 toSource:[
                     ...state.toSource.filter(x=>x.uuid !== action.payload.uuid),
                     action.payload
                 ]
            };

        default:
            return state;
     }
};

export default autolineReducer;