
import React from 'react';
import {useSelector} from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import {publish} from '../../utils/socket';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Translate from '../../components/Translate';
import noPicture from '../../images/no_picture.png';




const curDate =()=>{

    // Number.prototype.padLeft = function(base,chr){
    //     var  len = (String(base || 10).length - String(this).length)+1;
    //     return len > 0? new Array(len).join(chr || '0')+this : this;
    // }

    let d = new Date(),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();
        // hour  = d.getHours().padLeft();
        // minute = d.getMinutes().padLeft();
        // seconds = d.getSeconds().padLeft();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    // if(minute.length < 2){
    //     minute  = '0'+minute;
    // }
    // if(seconds.length < 2){
    //     seconds  = '0' + seconds;
    // }

    // return [year, month, day].join('-') +' '+[hour,minute,seconds].join(':');
    return [year, month, day].join('-');
};





const Research = ({oeBrandRef})=>{
    
    const {itemResearch} = useSelector(state=>state.autoline);
    const {PageOpenID:from}   = useSelector(state=>state.userLogin);
    const item = itemResearch.find(x=>x.refNumber === oeBrandRef);

    React.useEffect(()=>{
        (async()=>{
            try {
               await publish({
                    type:'AUTOLINE_ITEM_RESEARCH',
                    payload:{
                        oeBrandRef,
                        from
                    }
               });

            } catch(error){
               console.log(error.message);
            }
        })();
    },[oeBrandRef,from]);

    const [key,setKey] = React.useState('photo');

    const items =  ['photo','oe','dimension'];
    
    const switchRender = (type)=>
    {
          switch(type)
          {
             case 'photo':
                return <img src = {
                            /jpg/.test(item.imgUrl)
                            ?item.imgUrl
                            :noPicture
                        } 
                        alt = 'product'
                        width = '240' 
                       />
             case 'oe':
                return (
                    <ul className ='mt-3'>
                        {
                         item.oeNumbers.map((obj,i)=>{

                            return <li key = {i} className ='my-2'>{ `${Object.keys(obj)[0]}  :   ${Object.values(obj)[0]}`}</li>
                            // for (const [key, value] of Object.entries(obj)) {
                            //     return <li key = {i} className ='my-2'>{ `${key}  :   ${value}`}</li>
                            // }        
                          })   
                        }
                    </ul>
                );
             case 'dimension':
                  return (<ul className ='mt-3'>
                        {
                        item.dimension.map((obj,i)=>{

                            return <li key = {i} className ='my-2'>{ `${Object.keys(obj)[0]}  :   ${Object.values(obj)[0]}`}</li>
                            // for (const [key, value] of Object.entries(obj)) {
                          
                            // }  
                        })   
                        }
                    </ul>);
             default:
               return (
                   <span>type</span>
               );
          }
    }


    return (
       <div className = 'eparts-row shadow rounded'>
        
        {item ? (
           <div  className ='shadow p-3 mb-2 w-100' style = {{minHeight:'320px'}}>
                <Tabs
                    activeKey={key}
                    onSelect={(k) => setKey(k)}
                    className="my-1"
                >
                    {
                        items?.map((item,i)=>(
                            <Tab 
                            eventKey= {item} 
                            title={<Translate k={item} />}
                            key = {i}
                            >
                            {
                                
                                switchRender(item)
                            }
                            </Tab>
                        ))
                    }
                    
                </Tabs>
          </div>) : (
            <p  style = {{minHeight:'320px'}}>{`no research item found ${oeBrandRef}`}</p>
        )}
        
   

       </div>
    );
};
export default Research;