import { USER_LOGIN_SOCKET,USER_LOGIN_PAGEID, USER_LOGIN_USERINFO,USER_LOGOUT, USER_SEARCH_UPDATE, USER_CONTRACT } from "../constants/userConstants";

//with token,wechat could switch the account
//two steps USER_LOGIN_PAGEID,USER_LOGIN_USERINFO (token)
//USER_LOGIN_TICKET (pageID,userInfo) 
export const userReducer = (state = {
    socketID:'',
    userInfo:{openID:'',search:''},
    contract:{},
    token:'#',
    loginLoading:false
},action) => {
    switch (action.type){
        case USER_LOGIN_SOCKET:
            return {...state,socketID:action.payload.socketID,token:action.payload.token};
        case USER_LOGIN_PAGEID:
            if(action.payload?.openID)
            {
                sessionStorage.setItem('PageOpenID',action.payload.openID);
            }
            
            return {
                ...state,
                PageOpenID:action.payload?.openID ? action.payload?.openID :'#',
                loginLoading:action.payload?.loginLoading?action.payload?.loginLoading:false
            };
        //success with 4 random digits from 
        case USER_LOGIN_USERINFO:
            return {...state,userInfo:action.payload};
        case USER_SEARCH_UPDATE:
            return {...state,userInfo:{...state.userInfo,search:action.payload.search}};
        case USER_CONTRACT:
            return {...state,PageOpenID:action.payload.openID,userInfo:{...action.payload.userInfo},contract:action.payload.contract}; //contract object...
        //switch by openID and token number
        case USER_LOGOUT:
            sessionStorage.removeItem('PageOpenID');
            sessionStorage.removeItem('status');
            return {...state,PageOpenID:'#',userInfo:{}};
        default:
           return state;
    }
}