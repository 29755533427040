import './datetime.css';
import DateTimeDisplay from './DateTimeDisplay';
import useCountDown from './useCountDown';

const ExpiredNotice = () => {
  return (
    <div className="expired-notice">
      <span>已经超时</span>
      {/* <p>Please select a future date and time.</p> */}
    </div>
  );
};

const ShowCounter = ({ days, hours, minutes, seconds }) => {
  return (

    <>
     {
      typeof seconds === 'number' && (
        <div className="show-counter" >
          
        <span
              className="countdown-link"
        >
        <DateTimeDisplay isDanger={days <= 3} type="天" value={parseInt(days)} />
        <p>:</p>
        <DateTimeDisplay isDanger={false} type="小时" value={parseInt(hours)} />
        <p>:</p>
        <DateTimeDisplay isDanger={false} type="分" value={parseInt(minutes)} />
        <p>:</p>
        <DateTimeDisplay isDanger={false} type="秒" value={parseInt(seconds)} />
        </span>
        </div>
      )
     }
    </>
   


  );
};

const CountDownTimer = ({ targetDate }) => {
  const [days, hours, minutes, seconds] = useCountDown(targetDate);
  
  if(isNaN(days))
  {
    return (<></>);
  }

  if (days + hours + minutes + seconds <= 0) {
    return <ExpiredNotice />;
  } else {
    return <ShowCounter days={days} hours={hours} minutes={minutes} seconds={seconds} />;
  }
};

export default CountDownTimer;