
import { useState } from "react";
import LanguageContext  from "./contexts";
const LanguageProvider = ({children})=>{
     
     const [language,setLanguage] = useState('cn');//loggle to CN

     return (<LanguageContext.Provider value = {{language,setLanguage}}>
            {children}
        </LanguageContext.Provider>
     );
};

export default LanguageProvider;