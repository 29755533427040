import React from 'react';
import {useSelector} from 'react-redux';
import { publish } from '../../utils/socket';
import Loader from '../../components/Loader';

const PlaceOrder = ({data,setShowTable,showMessage,setMessage})=>{

    // let [summary,setSummary] = React.useState(data);
    let [date,setDate]     = React.useState('#');
    let [msg,setMsg]       = React.useState('');
    let [token,setToken]   = React.useState('');

    let [loading,setLoading]           = React.useState(false);

    let {task,message}             = useSelector(state=>state.task);
    


    const {PageOpenID}     = useSelector(state=>state.userLogin);
    // let total = data.reduce((acc,item)=>{
    //     return acc + item.price_old * (parseFloat(item.qty_change_to) > 0 ?item.qty_change_to :item.qty_to_buy);
    // },0);
    // console.log(data);
    let amount = data.filter(x=>x.flag === 'O').reduce((acc,item)=>{
        return acc + (parseFloat(item.price_new)?item.price_new:item.price_old) * (parseInt(item.qty_change_to)?item.qty_change_to:item.qty_to_buy)
    },0);
   
    const summary = {
        order_no:data[0]?.confirm_id,
        date : data[0]?.date_planned,
        // total:Math.round((total + Number.EPSILON) * 100) / 100,
        amount:Math.round((amount + Number.EPSILON) * 100) / 100,
        token :data[0]?.confirm_id.substring(8)
        // rate: Math.round((rate + Number.EPSILON) * 100) / 100 
    };

    let taskMsg = message?.find(x=>x.confirm_id === data[0].confirm_id);
   
    const MsgBox = (m)=>{
         setMsg(m);
         setTimeout(()=>{
            setMsg('');
         },3000);
    }

    const onChange = (e)=>{
        sessionStorage.removeItem('tempPage');
        setDate(e.target.value);   
        setShowTable(e.target.value !== '#'?true:false);
    };

    const orderHandler = async(e)=>{
         try {

    
            if(date === '#')
            {
                 return MsgBox('没有选择交期...');
            }

            //check token
            // const foundTask = task.find(t=>t.confirm_id === summary.order_no && t.token === token);
            if(token !== summary.token)
            {
                 return MsgBox('口令匹配错误');
            }
            
            // alert('ready to place order:'+summary.order_no);

            const findTask = task.find(t=>t.confirm_id === summary.order_no && t.flag === 'O');


            if(!findTask){
                 return MsgBox('没有找到可以提交的订单');
            }
            
            //@2024.06.19
            if(new Date() >= new Date(findTask.expire_time))
            {
                return MsgBox('超过接单时间,接单失败...');
            }
            const {from,to,manager} = findTask;
            // if(PageOpenID === to) 
            // {
            //      return alert('测试中,管理员才能提交订单...');
            // }
            let action = {type:'TASK_CONFIRM',payload:{
                  type:'TO_ORDER',
                  tableName:findTask.tableName,
                  confirm_id:findTask.confirm_id,
                  date_confirmed:summary.date,
                  openIDs : {from,to,manager}
            }}

            setLoading(true);

            setTimeout( ()=>{
                // console.log(action);
                publish(action);

                setLoading(false);
            },2000);
          
           


         } catch(error){
            //  console.log(error);
             console.log(error.message);
         }
    }

    let cls = true ? 'btn-outline-danger' : 'btn-outline-seconday';

    let newDate = new Date(summary.date);
    let secondDate = [
               newDate.getFullYear(),
               (newDate.getMonth()+2+'').length > 1
               ?newDate.getMonth()+2+'':'0'+(newDate.getMonth()+2),
                newDate.getDate()
        ].join('-');

    
    return (
        <>
          {taskMsg !== undefined && taskMsg?.msg ? (<h5 className ='text-danger mt-3 mx-auto' >{taskMsg.msg}</h5>):( <div className ='mx-auto'>
            <div className='form-group mt-3' style={{marginBottom:'0.35rem'}}>
                {/* <span className ='ml-2 mr-2 text-dark'><b>接单率</b></span> */}
                {/* <span className ='mx-2 text-dark' style={{fontSize:'105%'}}>订单金额:<b className='text-danger'>{summary.total.toFixed(2)}</b></span> */}
                <span className ='mr-2 text-dark ml-2'   style={{fontSize:'105%'}}>接单金额:<b className='text-danger'>{summary.amount.toFixed(2)}</b></span>
            </div>
            <div className='form-group ml-2' style ={{maxWidth:'300px'}}>
            <div className ='input-group'>
                <select 
                    className='form-control eparts-input' 
                    onChange = {onChange}
                    style = {{minWidth:'60px',padding:'3px'}}
                >
                    <option value={'#'}>交期选择</option>
                    <option value={summary.date}>{summary.date}</option>
                    {/* <option value={'2024-08-20'}>{'2024-08-20'}</option> */}
                    {/* {/H0221|H0219/.test(summary.order_no) && (<option>{secondDate}</option>)} */}
                </select>
                <input 
                   type ='text' 
                   className ='form-control eparts-input ml-3' 
                   placeholder ={`口令:${summary.token}`} 
                   value = {token}
                   onChange = {(e)=>setToken(e.target.value)}
                />
                <div className ='input-group-append'>
                    {loading ?(<span className={`btn btn-sm ${cls} text-danger rounded`}><Loader size ='20px'  /></span>) : (<span 
                        className = {`btn btn-sm btn-sm ${cls} rounded p-1`}
                        onClick = {orderHandler} 
                    >
                        <i className='fa fa-save'  
                            onClick = {orderHandler} 
                           style = {{fontSize:'130%'}}
                        ></i>
                    </span>)}
                   
                    {[].includes(PageOpenID) && (
                        <span 
                            className ='btn btn-sm btn-outline-success rounded ml-2'
                            onClick = {()=>setMessage(!showMessage)} 
                        >
                           <i className='fa fa-commenting-o'></i>
                        </span>
                    )}
                </div>
            </div>
            </div>
            <div className='form-group ml-2 text-danger' style = {{maxWidth:'300px'}}>
                 {msg && (<span className ='text-danger mx-auto' style ={{fontWeight:500}}>{msg}</span>)}
                 
            </div>
       </div>
          )}
        </>
       
    );
}
export default PlaceOrder;