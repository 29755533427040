import { TODO_APPEND, TODO_CONFIRM, TODO_FETCH, TODO_REMOVE, TODO_UPDATE } from "../constants/todoConstants";


const todoReducer = (state={data:[],errMsg:[],confirm:[]},action)=>{
    
     switch(action.type)
     {
        case TODO_FETCH:
         //   console.log(action.payload);
           return {...state,data:action.payload};
        case TODO_UPDATE:
            // console.log('to update',action.payload);
           return {
                   ...state,
                   data:state.data.map(x=>x._id === action.payload._id?action.payload:x)
           };
        case TODO_APPEND:
           const existTodos = state.data.filter(x=>x._id !== action.payload._id);
           return {
                 ...state,
                 data:[
                     ...existTodos,
                     action.payload
                 ]
           };
        case TODO_CONFIRM:
           return {};
        case TODO_REMOVE:
         //   console.log('the payload',action.payload);
           return {...state,data:[...state.data.filter(x=>x._id !== action.payload._id)]};
        default:
            return state;
     } 
};

export default todoReducer;