import { v4  } from 'uuid';
import axios from 'axios';
import React from 'react';
import {useSelector} from 'react-redux';
import { useAsyncDebounce } from 'react-table';
import Research from "../autoline/Research";
// import { publish } from '../../utils/socket';

const BomDesign = ({row,handlePublish})=>{

     const {PageOpenID} = useSelector(state=>state.userLogin);

     const {_id,type,status} = row;
    //  const [dataRow,setDataRow] = React.useState(row.original);
     const {refs,autoline,productType} = row.data[0].row;

     const [bomList,setBomList] = React.useState([]);

     const [last,setLast]       = React.useState('...');
     const [value,setValue]     = React.useState('');
     const [qty,setQty]     = React.useState(1);


     const findLast = useAsyncDebounce((value)=>{
           (async()=>{
               
                if(value.length !== 3) return;
              
                //http_request
                const config = {
                    headers : {
                       'Content-Type':'application/json'
                       // Authorization: `Bearer ${ userInfo.token }`
                    }
                };
                
                //EDIT => update the record
                const URL =process.env.NODE_ENV === 'production'
                ?'https://service.eparts.cn/api/last'
                :'http://localhost:4000/api/last'; 
                
                //get the last 
                const {data:{msg}}  = await axios.post(
                    URL,
                    {item:value},
                    config
                );

                setLast(msg.trim().toUpperCase());
            
           })();
     },200);

 
     // add an input-group
     const changeHandler = (e)=>{
         if(e.target.type === 'text') {
            setValue(e.target.value.toUpperCase());
            findLast(e.target.value.toUpperCase());
 
         } else {
            if(e.target.value !== 'QTY')
            { 
                setQty(e.target.value);
            }
         }  
     };

     const addBom = (e)=>{

         
         setBomList(
            [
               ...bomList,
               {

                  ...row.data[0].row,
                  uuid:v4(),
                  part_no:value,
                  qty
               }
           ]
         );

     }
     
    
     const submitHandler = async()=>{

        try {

            if(bomList.length === 0)
            {
                return alert('没有BOM,不能提交...');
            }
    
            if(window.confirm('确认提交?'))
            {
               handlePublish({
                    _id,
                    type:'TODO_UPDATE',
                    PageOpenID,
                    payload : {
                        type,
                        payload:bomList
                    },
                    status
                });
            }

        } catch(error){
             console.log(error.message);
        }
                                    
      
     
   }

     return (
        
        <div className ='row'>
             <div className ='col-md-6 col-xs-12'>
                {<Research oeBrandRef={ refs[0]} />}
                {/* <pre>{JSON.stringify(bomList,null,2)}</pre> */}
             </div>
             <div className ='col-md-6 col-xs-12'>
                
                   {/* <div className ='form-group mt-3 mb-3'>
                       <span 
                         className='btn  btn-outline-danger btn-sm rounded'
                         onClick = {()=>alert('add a row')}  
                        >
                           <i className ='fa fa-plus'></i>
                       </span>
                   </div> */}
                   <div className ='card ' style = {{minHeight:'380px'}}>
                       <div className ='card-header bg-light p-0 border-0 mt-2'>
                            <span 
                               className='btn  btn-outline-danger btn-sm rounded pull-right mr-4'
                               onClick = {submitHandler} 
                            >
                                <i className = 'fa fa-paper-plane'></i>
                            </span>
                       </div>

                       <div className ='card-body'>
                              <div className ='form-group'>
                                    <label> 
                                        {autoline + ' | ' +row.itemNumber + ' | ' + productType}   
                                    </label>
                                    <div className ='input-group'>
                                        <input 
                                             type = 'text'  
                                             className ='form-control eparts-input'  
                                             placeholder={`${last}`} 
                                             value = {value}
                                             onChange = { changeHandler }
                                             
                                        />
                                        <select
                                             className = 'form-control eparts-input'
                                             onChange = { changeHandler }
                                        >
                                             <option>QTY</option>
                                             <option>1</option>
                                             <option>2</option>
                                             <option>3</option>
                                             <option>4</option>
                                             <option>5</option>
                                             <option>6</option>
                                        </select>
                                        <span 
                                            className ='btn btn-sm btn-outline-danger rounded' 
                                            onClick = {addBom}
                                            >
                                            <i className ='fa fa-plus'></i>
                                        </span>
                                    </div>
                                    <span className ='text-danger'>{last}</span>
                                </div>
                                <div className ='form-group mt-3'>
                                        {
                                             bomList?.length > 0 && (
                                                <ul>
                                                     {
                                                         bomList.map((b,i)=>{
                                                             return (
                                                                <li 
                                                                   key = {b.uuid}
                                                                   onDoubleClick={
                                                                     ()=>setBomList([
                                                                       ...bomList.filter(x=>x.uuid !== b.uuid)
                                                                     ])
                                                                   }
                                                                >
                                                                     {b.part_no + ' x ' + b.qty}
                                                                </li>
                                                             )
                                                         })
                                                     }
                                                </ul>
                                             )
                                        }
                                </div>  
                       </div>
                   </div>   
             </div>
        </div>
        
     );
};


export default BomDesign;