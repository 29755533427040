
import { Card } from 'react-bootstrap';
// import nsk from '../../images/NSK.png';
import {useSelector} from 'react-redux';
import TodoTable from '../../tables/todo/TodoTable';

//prepare the card to render the ticket
const RenderTask = ({toggle})=>{
    const {data} = useSelector(state=>state.todo);

    return (
        <Card className ='border-0'>
           <Card.Header className ='bg-light shadow header-height rounded-0 pb-3'>  
               {/* <img 
                 src = {nsk} 
                 width='52' 
                 className ='pull-right'
                 onClick =  {toggle}
               /> */}
           
               <span
                className ='text-danger pull-right'
                onClick =  {toggle}
               >
                  <i className = 'fa fa-list'></i>
               </span>
           </Card.Header>
           <Card.Body style = {{minHeight:'62vh'}}>
               {/* {<pre>{JSON.stringify(data,null,2)}</pre>} */}

               <TodoTable />

               {/* <p className ='text-danger'>render the bucket controller</p> */}
           </Card.Body>
        </Card>
    )
};

export default RenderTask;