
import React from 'react';
import {useSelector} from 'react-redux';
import ForecastMain from './ForecastMain';
import Approve from './Approve';
import { Badge } from 'react-bootstrap';
// import Translate from '../../components/Translate';

const ForecastTable = ()=>{
    
    const {toList} = useSelector(state=>state.forecast);
    console.log('toList',toList);

    const [showApprove,setShowApprove] = React.useState(false);

    const handleShow = ()=>{
         setShowApprove(!showApprove);
    }

    return (
        <>
           {
             /* toList?.length > 0*/
             showApprove
             ? (
               <>
                  <div className ='eparts-row'>
                        <span style={{position:'relative'}}>
                            <span 
                              className={`btn btn-sm btn-outline-danger rounded`}
                              onClick = {()=>handleShow()}
                            >
                              <i className='fa fa-list'></i>
                            </span>
                          
                            <Badge
                                className='rounded-circle text-danger border-danger p-1' 
                                bg = 'light'
                                style = {{position:'absolute',top:'-10px',right:'-10px',fontSize:'85%',backgroundColor:'#e2e2e2'}}   
                            >
                            {toList?.length}
                            </Badge>
                        </span>
                   </div>
                   <div className ='eparts-row'>
                            <div className ='mx-auto'>{toList?.length > 0 ?(<h5>APPROVE ITEMS</h5>):(<h5>NO NEW ITEMS</h5>)}</div>
                   </div>
                
                 {
                    toList.map((item,i)=>{
                         return (
                            <Approve key = {i} item = {item} />
                         )
                    })
                 }
               </>

             ): (
                <ForecastMain handleShow = {handleShow}  />
               )
             
             }
        
        </>
    );
};

export default ForecastTable;