import React from 'react';
import { useParams,useNavigate } from 'react-router-dom';
import {useSelector} from 'react-redux';
import { publish } from '../utils/socket';
import Pallet from '../components/Pallet';
import Loader from '../components/Loader';
import TodoTable from '../tables/todo/TodoTable';

const Subscriber= ()=>{
    const navigate = useNavigate();

    const [loading,setLoading] = React.useState(false);
    
    const {data:todos} = useSelector(state=>state.todo);
    const {userInfo} = useSelector(state=>state.userLogin);

    const {ref} = useParams();

    React.useEffect(()=>{

      if(!ref)
      {
          navigate('/login');
      }

    },[userInfo]);
    
    React.useEffect(()=>{
        if(!ref) return;
        setLoading(true);

        const openID = ref.split('::')[0];
        const ticketID = ref.split('::')[1];
        const logType  = 'subscribe';
        // console.log(ref,openID,ticketID)
        publish({
          type:'TASK_LOGIN',
          openID,ticketID,logType
        })

        setTimeout(()=>{
            setLoading(false);
        },2000);


    },[ref]);
    
    return (
        <>
          {
           loading ? (<Loader />) :( todos?.length > 0 ?(<TodoTable />):(<Pallet />))
          }
        </> 
    );
}

export default Subscriber;