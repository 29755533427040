export const USER_LOGIN_REQUEST = 'USER_LOGIN_REQUEST';
export const USER_LOGIN_SOCKET  = 'USER_LOGIN_SOCKET'; // attache the socket id...
// export const USER_LOGIN_SUCCESS  = 'USER_LOGIN_SUCCESS';//
export const USER_LOGIN_PAGEID   = 'USER_LOGIN_PAGEID';//
export const USER_LOGIN_USERINFO = 'USER_LOGIN_USERINFO';//
export const USER_LOGIN_TICKET   = 'USER_LOGIN_TICKET';

export const USER_CONTRACT       =  'USER_CONTRACT'; // have the contract data,and prepare the contract details...
// export const USER_LOGIN_SWITCH  = 'USER_LOGIN_SWITCH';//
export const USER_LOGIN_FAIL     = 'USER_LOGIN_FAIL';
export const USER_SEARCH_UPDATE  = 'USER_SEARCH_UPDATE';
// export const USER_LOGIN_UPDATE  = 'USER_LOGIN_UPDATE';
export const USER_LOGOUT         = 'USER_LOGOUT';
