
import React from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from "react-redux";
import { publish } from '../utils/socket';
// import TaskTable from "../tables/TaskTable";
// import PreEcho from "../components/PreEcho";
import ToBuy from '../tables/tasks/ToBuy';
import Burger from '../components/Burger';
import Loader from '../components/Loader';
import Pallet from '../components/Pallet';
// import TalkOnProduct from '../components/source/TalkOnProudct';
const Task = () => {

    let [loading,setLoading] = React.useState(true);

    let {openID} = useParams();
    // const {PageOpenID} = useSelector(state=>state.userLogin);
    let PageOpenID = sessionStorage.getItem('PageOpenID') ?sessionStorage.getItem('PageOpenID'):'oV9Cut8y8vzP3DzlLZWIJ8RNfdn8';
    
    const {task} = useSelector(state=>state.task);

    openID = openID 
             ? openID 
             :(PageOpenID?PageOpenID:'oV9Cut8y8vzP3DzlLZWIJ8RNfdn8');

    
    React.useEffect(()=>{

       setTimeout(()=>{
          setLoading(false);
       },3000);

       if(!task?.length)
       {
         publish({type:'TASK_LOGIN',openID});
       }

    },[]);
    
    
    // let burgers = {};
    let burgers = {};
    task?.forEach(t=>{
        if(!Object.keys(burgers).includes(t.confirm_id))
        {
            const {tableName} = t;
            burgers[t.confirm_id] = {tableName,data:task.filter(x=>x.confirm_id === t.confirm_id)}; 
        }    
    });
    // console.log('burgers',burgers);
    // console.log(tables);

    return (
        <>
        
          {/* <PreEcho obj = {task} /> */}
          {task?.length === 0 && (<div className ='eparts-row shadow'>{loading ? (
               <Loader />
          ):(<Pallet />)}
          </div>)}
          {
  
             Object.keys(burgers)?.length > 0  && (Object.keys(burgers).map(
               b =>{
                  return (
                    <Burger id = {b} key ={b}>
                      { burgers[b].tableName === 'eparts_app.tb_to_buy' && (<ToBuy  data = {burgers[b].data}  key = {'burger'+b}/>)}
                    </Burger>
                  )
               }
            )) 
          }
        </>
  
    );
};

export default Task;