import axios from 'axios';
import React from 'react';
import {useSelector} from 'react-redux';
import { Modal } from 'react-bootstrap';
import CloseBtn from '../../components/CloseBtn';
import Loader from '../../components/Loader';
import { publish } from '../../utils/socket';
const Drawing = ({ref_no,handle})=>{
    const [loading,setLoading] = React.useState(false);
    const [err,setErr]         = React.useState('');
    const [show,setShow] = React.useState(true);
    const [file,setFile] = React.useState('');
   
    React.useEffect(()=>{
       
        (async()=>{

            try {
                
                setLoading(true);
                //http_request
                const config = {
                    headers : {
                       'Content-Type':'application/json'
                       // Authorization: `Bearer ${ userInfo.token }`
                    }
                };
                
                //EDIT => update the record
                const URL =process.env.NODE_ENV === 'production'
                ?'https://service.eparts.cn/api/approve'
                :'http://localhost:4000/api/approve'; 
              
         
                const {data}  = await axios.post(URL,{ref_no},config);
                // console.log('response',data);
                setLoading(false);

                if(/ERROR/.test(data.errMsg)){
                    throw new Error(data.msg);
                }
                
                setFile(data.msg);
        
            } catch(error){
                // setErr(error.message);     
            }
           

        // })();

        })();

    },[ref_no]);

    const handleClose = ()=>{
          setShow(false);
          handle();
    }
     

    return (
        <Modal show={show} onHide={handleClose} className='modal-template'>
          <Modal.Header>
            <h4>DRAWING - {ref_no}</h4>
            <CloseBtn onClick ={handleClose} />
          </Modal.Header>
          <Modal.Body className='dwg-body'>
             {loading && (<Loader />)}
             {err && (<p className ='my-3'>{err}</p>)}
             {file && (<embed src ={`data:application/pdf;base64,${file}`} className='dwg-embed'/>)}
          </Modal.Body>
        </Modal>
    );
};

const Photo = ({item,handle})=>{
    const [loading,setLoading] = React.useState(false);
    const [err,setErr]         = React.useState('');
    const [show,setShow] = React.useState(true);
    // const [file,setFile] = React.useState('');
    const [product,setProduct] = React.useState('');
   
    React.useEffect(()=>{
       
        (async()=>{

            try {
                
                setLoading(true);
                //http_request
                const config = {
                    headers : {
                       'Content-Type':'application/json'
                       // Authorization: `Bearer ${ userInfo.token }`
                    }
                };
                
                //EDIT => update the record
                const URL =process.env.NODE_ENV === 'production'
                ?'https://service.eparts.cn/api/getphoto'
                :'http://localhost:4000/api/getphoto'; 
              
                const {data}  = await axios.post(
                    URL,
                    {refNumber:item.baseRef},
                    config
                );
                // console.log('response',data);
                setLoading(false);

                if(/ERROR/.test(data.errMsg)){
                    throw new Error(data.errMsg);
                }
                setProduct(data.product);
                // setFile(data.msg);
        
            } catch(error){
                // setErr(error.message);     
            }
           

        // })();

        })();

    },[item]);

    const handleClose = ()=>{
          setShow(false);
          handle();
    }
     

    return (
        <Modal show={show} onHide={handleClose} className='modal-template'>
          <Modal.Header>
            <h4>PHOTO {item.brand_ref} / {item.baseRef}</h4>
            <CloseBtn onClick ={handleClose} />
          </Modal.Header>
          <Modal.Body className='dwg-body text-center'>
             {loading && (<Loader />)}
             {err && (<p className ='my-3'>{err}</p>)}
             {/* {file && (<embed src ={`data:application/pdf;base64,${file}`} className='dwg-embed'/>)} */}
             {/* {product && (<embed src ={product.imgUrl} className='dwg-embed'/>)} */}

             {product && (
                <>
                    <div className ='row text-cener'>
                        <ul className='mx-auto'>
                            {
                                product?.dimension?.map((obj,i)=>{
                                    return (
                                        Object.keys(obj).map((key,i)=>{
                                            return (
                                                <li>
                                                    <span className='float-left'>{key}</span>:<span className ='text-danger'>{obj[key]}</span>
                                                </li>
                                            )
                                        })
                                    )
                                })
                            }
                        </ul>
                    </div>
                    <div className ='row'>
                            <img src ={product.imgUrl} width = '450'  className ='mx-auto' />
                    </div>

                </>
             
         
                
             )}
             {/* <pre>{JSON.stringify(product,null,2)}</pre> */}
          </Modal.Body>
        </Modal>
    );
};
const Comment = ({price,handle})=>{
    const [loading,setLoading] = React.useState(false);
    const [err,setErr]         = React.useState('');
    const [show,setShow] = React.useState(true);
    const [comment,setComment] = React.useState('');
   
    React.useEffect(()=>{
       
        (async()=>{

            try {
                
                setLoading(true);
                //http_request
                const config = {
                    headers : {
                       'Content-Type':'application/json'
                       // Authorization: `Bearer ${ userInfo.token }`
                    }
                };
                
                //EDIT => update the record
                const URL =process.env.NODE_ENV === 'production'
                ?'https://service.eparts.cn/api/comment'
                :'http://localhost:4000/api/comment'; 
              
         
                const {data}  = await axios.post(URL,price,config);
                // console.log('response',data);
                setLoading(false);

                if(/ERROR/.test(data.errMsg)){
                    throw new Error(data.msg);
                }
                setComment(data.msg.trim());
        
            } catch(error){
                // setErr(error.message);     
            }
           

        // })();

        })();

    },[]);

    const handleClose = ()=>{
          setShow(false);
          handle();
    }
     

    return (
        <Modal show={show} onHide={handleClose} className='modal-template'>
          <Modal.Header>
            <h4>comments - {price.brand_ref}</h4>
            <CloseBtn onClick ={handleClose} />
          </Modal.Header>
          <Modal.Body className='dwg-body'>
             {loading && (<Loader />)}
             {err && (<p className ='my-3'>{err}</p>)}
             {comment && (<p className ='p-3 text-danger display-5'>{comment}</p>)}
          </Modal.Body>
        </Modal>
    );
};


const Approve = ({item})=>{

    const [show,setShow] = React.useState(false);
    const [commentShow,setCommentShow] = React.useState(false);
    const [viewed,setViewed] = React.useState(false);
    const [comment,setComment] = React.useState('');

    const {userInfo} = useSelector(state=>state.userLogin);

    const [loading,setLoading] = React.useState(false);
    const [showItem,setShowItem] = React.useState(true);

    const handle = ()=>{
          setShow(!show);
          setViewed(true);
    }

    const handleComment = ()=>{
        setCommentShow(!commentShow);
    }


    const submitHandler = (e)=>{
         
         (async()=>{

             try {

                if(viewed === false)
                {
                    return alert('Please check the drawing...');
                }

                if(!comment && !window.confirm('Confirm '+item.data[0].row.ref_no+'?')) return;
                
                setLoading(true);

                publish({
                    type:'FORECAST_LIST_UPDATE',
                    payload:{
                        comment,
                        row:item
                    }
                });

                setTimeout(()=>{
                    setLoading(false);
                },2000);
                
             } catch(error){
                console.log(error.message);

             }

         })();

    }
   
    return (
      <div className ='row'>
        {show && <Drawing ref_no = {item.data[0].row.ref_no} handle = {handle} />}
        {show && <Photo item = {item.data[0].row.price} handle = {handle} />}
        {commentShow && <Comment price = {item.data[0].row.price} handle = {handleComment} />}
        <div className = 'form-group mx-auto my-3 shadow p-3 rounded'>
           <label className='text-dark'>
             <b>{item.data[0].row.ref_no} | {item.data[0].row.item_code} | price: <span className ='text-danger'>USD:{item.data[0].row.price.price}</span></b>
             {item.status === 'O' 
               ? (<i className ='fa fa-check text-danger ml-2'></i>)
               : (item.status === 'P' && (<i className ='fa fa-times text-danger ml-2'></i>))
             }
          </label>
           <div className='input-group'>
              <div className ='input-group-prepend'>
                 { /^03\./.test(item.data[0].row.ref_no) ? 
                 (
                    <span 
                    className ={`btn btn-sm btn-outline-${viewed?'secondary':'danger'} rounded`}
                    onClick   = {handle}
                    ><i className ='fa fa-file-pdf-o'></i></span>
                 ):(
                    <span 
                        className ={`btn btn-sm btn-outline-${viewed?'secondary':'danger'} rounded`}
                        onClick   = {handle}
                    ><i className ='fa fa-photo'></i></span>
                 )}
            


                 {/* <span 
                     className ={`btn btn-sm btn-outline-danger rounded`}
                     onClick   = {handleComment}
                 >
                     <i class='fa fa-comment-o'></i>
                 </span> */}
              </div>
              <input 
                 type = 'text' 
                 name ='comments' 
                 placeholder ='...' 
                 className ='form-control eparts-input'
                 style = {{minWidth:'280px'}}
                 value = {comment}
                 onChange = {(e)=>setComment(e.target.value)}
                 readOnly = {viewed?false:true}
              />
              
                 <div className ='input-group-append'>
                 {  
                   loading ? (
                    <span
                       className ='btn btn-sm btn-outline-warning rounded'
                    >
                           <Loader size ='15' />
                    </span>
                  
                   ):(

                    <span 
                    className ='btn btn-sm btn-outline-danger rounded'
                    onClick = {submitHandler} 
                    >
                    
                    {
                    comment  ?  
                    (<i className='fa fa-comment text-warning'></i>)
                    :( <i className ='fa fa-check'></i> )
                    }
                        
                    </span>
                   )
                 }
                 
               
                  </div>
              
           </div>
           <p className='mt-3 text-dark' style ={{inlineSize:'350px'}}>{item.data[0].row.price.comments}</p>
           <span className ='my-3 text-danger'>{comment}</span>

           {userInfo?.logType === 'proxy' && showItem && (
                <span 
                    className='btn btn-sm btn-outline-warning rounded float-right'
                    onClick  = {()=>{

                        if(!window.confirm('ARE YOU SURE TO TAG AS DELETE?')) return;

                        publish({
                            type:'FORECAST_LIST_UPDATE',
                            payload:{
                                row:item,
                                tagStatus:'D' //delete
                            }
                        });

                        setShowItem(!showItem);
                    }}
                >
                    <i className ='fa fa-times'></i>
                </span>
           )}
           
       </div>
        {/* <pre>{JSON.stringify(item,null,2)}</pre> */}
      </div>
    );
  
};

export default Approve;