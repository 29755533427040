import { PAYMENT_LIST, PAYMENT_REMOVE, PAYMENT_SUCCESS } from "../constants/paymentConstants";

export const paymentReducer = (state = {payment:[],msg:''},action) => {
    switch (action.type){
        case PAYMENT_LIST:
            return {...state,payment:action.payload};
        case PAYMENT_SUCCESS:
            return {...state,payment:state.payment.map(r=>r.uuid === action.payload.uuid ? action.payload:r)};
        case PAYMENT_REMOVE:
            return {...state,payment:state.payment.filter(r=>r.uuid !== action.payload.uuid)};
        default:
           return state;
    }
}