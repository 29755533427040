import axios from 'axios';
import React from 'react';
import Loader from '../Loader';

const ImageBox = ({row}) => {

     const {uuid} = row;

     const [file,setFile] = React.useState('');

     const [loading,setLoading] = React.useState(false);
    //  const forRender = React.useMemo(()=>file,[file]);
     React.useEffect(()=>{
         (async()=>{
            try {
                setLoading(true);
                const config = {
                    headers : {
                    'Content-Type':'application/json'
                    // Authorization: `Bearer ${ userInfo.token }`
                    }
                };
                
                //EDIT => update the record
                const URL =process.env.NODE_ENV === 'production'
                ?'https://service.eparts.cn/api/image'
                :'http://localhost:4000/api/image'; 

                const {data:{status,image,err}}  = await axios.post(URL,{uuid},config);

                if(err)
                {
                    throw new Error(err);
                }
                setLoading(false);
                setFile(image);

            } catch (error){
                setLoading(false);
                alert('ImageBox error:',error.message);
            }
         })();
     },[]);
     
     return (
        <>  
           <div className ='row'>
                <span
                    className="btn btn-outline-danger rounded-circle  border-0 w-40px h-40px ml-2"
                    style = {{fontSize:'105%'}}
                    onClick = {()=>alert('parse VIN...')}
                >
                    <i className='fa fa-paper-plane-o'></i>
                </span>
           </div>
           <div>
              {loading && <Loader size = {'30px'} />}
              {file && (<img src = {`data:image/jpeg;base64,${file}`}  width = '280' alt ='image' onClick = {()=>alert('handle VIN...')} />)}
           </div>
        </>
        

     )
   
     
};

export default ImageBox;