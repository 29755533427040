/* eslint-disable react/no-direct-mutation-state */
/* eslint-disable */ 
import React from 'react';
import {useSelector} from 'react-redux';

import { publish } from '../../utils/socket';
import Translate from '../../components/Translate';
import { useTable,useRowState,useFilters,useGlobalFilter,useExpanded,usePagination } from 'react-table';
import useTableState from '../../hooks/useTableState';
import BTable from 'react-bootstrap/table';
import { Row } from 'react-bootstrap';

import LanguageContext from '../../contexts/contexts';
import GlobalFilter from '../../components/GlobalFilter';

// import Xcross from './Xcross';
import BomDesign from './BomDesign';
import Source from './Source';
import Price from './Price';

// import WechatPayment from '../../components/WechatPayment';
// import { isMobile } from '../../utils';
import PrepareDrawing from './PrepareDrawing';
import Default from '../../components/Default';
import Scross from './Scross';
import BrandRef from './BrandRef';
import Loader from '../../components/Loader';
import TakePhoto from './TakePhoto';
import Claim from './Claim';


function RenderStep({row,handlePublish})
{
      const {type} = row.original; //payload one 
      switch(type)
      {
          // case 'XCROSS':
          //   return <Xcross row = {row} />
          case 'brandRef':
            return <BrandRef row = {row.original} handlePublish = {handlePublish} />
          case 'PHOTO':
            return <TakePhoto row = {row.original} handlePublish = {handlePublish} />
          case 'BOM_DESIGN':
            return <BomDesign row = {row.original} handlePublish = {handlePublish} />
          case 'source':
            return <Source row = {row} handlePublish = {handlePublish} />
          case 'price':
            return <Price  row = {row.original} handlePublish = {handlePublish} />
          case 'DRAWING':
            return <PrepareDrawing row = {row.original} handlePublish = {handlePublish} />
          case 'SCROSS':
            return <Scross row = {row} handlePublish={ handlePublish} />
          case 'CLAIM':
            return <Claim row = {row.original} handlePublish={ handlePublish} />
          
          default:
             return <pre>{JSON.stringify(row.original,null,2)}</pre>
      }
}

function Table({ columns, data,hides}) {
    

    const {userInfo} = useSelector(state=>state.userLogin);

    const {language} = React.useContext(LanguageContext);

    const statusObject  = sessionStorage.getItem('status') 
                         ? JSON.parse(sessionStorage.getItem('status'))
                         : {};


    const extraColumns =  userInfo?.open_id && userInfo.open_id === 'oV9Cut8y8vzP3DzlLZWIJ8RNfdn8' 
          ?[ {
            id:'action',
            Header:<Translate k= {'action'} />,
            maxWidth: 8,
            minWidth: 5,
            width: 5,
            Cell: ({cell,handlePublish})=>{
            
              const [loading,setLoading] = React.useState(false);

              return (
                  <span 
                    className='btn btn-sm btn-outline-danger rounded'
                    onClick = {()=>{
                      
                        if(window.confirm('are you sure to delete?'))
                        {
                            setLoading(true);
                    
                            handlePublish({
                              type:'TODO_REMOVE',
                              payload:cell.row.original
                            })

                            setTimeout(()=>{
                                setLoading(false);
                            },3000);
                        }
                      
                    }}
                  >
                       {
                         loading 
                         ? <Loader size ='12'  className = 'border-warning'/> 
                         : <i className ='fa fa-times'></i>
                       }
                  </span>
              )
            }
          }] : [];
    
    const tableInstance = useTable(
     {
      columns,
      data,   
      initialState:{ 
          hiddenColumns:hides,
          pageSize:5,
          pageIndex   :statusObject?.pageIndex?statusObject.pageIndex:0,
          globalFilter:statusObject?.globalFilter?statusObject?.globalFilter:undefined,
          expanded:statusObject?.expanded?statusObject.expanded:{},
          filters:statusObject?.filters ? statusObject?.filters :[],
          tableName:'todos'
      }
    },
    useRowState,
    hooks=>{
        hooks.visibleColumns.push(columns=>[
          {
           id:'TODO',
           Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }) => (
             <span {...getToggleAllRowsExpandedProps()} className ='text-danger'>
               {isAllRowsExpanded ? '...' : (<Translate k = 'todo' />)}
             </span>
           ),
           // accessor:k,
           // ...getWidth(k),
           maxWidth: 8,
           minWidth: 5,
           width: 5,
           Cell: ({ row }) => {
            return (
             <span className='text-danger' {...row.getToggleRowExpandedProps()} >
               {row.isExpanded ? (<i className='fa fa-minus'></i>) : (<i className='fa fa-plus'></i>)}
             </span>
           )}
          },
          ...columns,
          ...extraColumns  //append the extra columns
   
        ]);
     },
     useFilters,
     useGlobalFilter,
     useExpanded,
     usePagination,
     useTableState
    );

    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      page, //instead of rows
      prepareRow,
      visibleColumns,
      state: { globalFilter,pageIndex,expanded,filters},
      preGlobalFilteredRows,
      setGlobalFilter,

      //pagination
      canPreviousPage,
      canNextPage,
      pageOptions,
      pageCount,
      gotoPage,
      nextPage,
      previousPage,

      handlePublish //get from useTableState
    } = tableInstance;
    
  
    // Render the UI for your table
    return (
      /* ...getTableProps() */
      <>

      <div className ='mx-auto'>
            <GlobalFilter
                preGlobalFilteredRows={preGlobalFilteredRows} 
                globalFilter={globalFilter} 
                setGlobalFilter={setGlobalFilter}
                language={language}
            />
      </div>
      
      {/*--- 55vh to 70% ----*/}
      <section style = {{height:'70%'}}>
         { data?.length > 0 && userInfo?.open_id ?(
           <>
             <BTable  bordered hover size="sm"  { ...getTableProps()}>
             <thead>
               {headerGroups.map(headerGroup => (
                 <tr {...headerGroup.getHeaderGroupProps()}>
                   {headerGroup.headers.map(column => (
                     <th {...column.getHeaderProps()}>{
                         column.canFilter && column.id === 'type' ? column.render('Filter') : column.render('Header')      
                     }</th>
                   ))}
                 </tr>
               ))}
             </thead>
             <tbody {...getTableBodyProps()}>
               {page.map((row, i) => {
                 prepareRow(row)
                 return (
                   <React.Fragment key = {i}>
                         <tr {...row.getRowProps()}>
                         {row.cells.map((cell,j) => {
                            return <td {...cell.getCellProps()} style = {{padding:'6px'}} key = {j}>{cell.render('Cell')}</td>
                         })}
                         </tr>
                         {
                             row.isExpanded  && (
                             <tr>
                                 <td colSpan = {visibleColumns.length} className='p-3'>{
                                     <RenderStep row = {row} handlePublish={handlePublish} />
                                 }</td>
                             </tr>
                             )
                         }
                   </React.Fragment>
                 )
               })}
             </tbody>
             </BTable>
           {/* {isMobile() && (<div className ='eparts-row'>
               <WechatPayment />
           </div>)} 
           style = {{position:'absolute',bottom:'30px'}}
           */}
           
           <Row className='ml-1 p-1 text-danger pagination d-none d-sm-block' >
               <button tabIndex="-1" onClick={() => gotoPage(0)} disabled={!canPreviousPage} className='btn btn-sm btn-default'>
               {'<<'}
               </button>{' '}
               <button  onClick={() => previousPage()} disabled={!canPreviousPage} className='btn btn-sm btn-default'>
               {'<'}
               </button>{' '}
               <button  onClick={() => nextPage()} disabled={!canNextPage} className='btn  btn-sm btn-default'>
               {'>'}
               </button>{' '}
               <button tabIndex="-1" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage} className='btn  btn-sm btn-default'>
               {'>>'}
               </button>{' '}
               <span className='btn btn-sm btn-default'>
               {language==='en'?'Page':'页'}
               {' '}
               <strong>
                   {pageIndex + 1} / {pageOptions.length}
               </strong>{' '}
     
               <span className ='ml-2'>
               {language==='en'?'TOTAL':'合计'} : { preGlobalFilteredRows.length }
               </span>
     
               </span>
             
           </Row>
           </>
         ):(
          <Default />
         )
        }
      </section>
    

      </>
    )
};
function SelectFilter({column:{filterValue,setFilter, preFilteredRows, id}}) {

  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach(row => {
      options.add(row.values[id])
    })
    return[...options.values()].sort();
  }, [id, preFilteredRows]);


  const steps = [
    {seq:0,value:'PHOTO'},
    {seq:1,value:'DRAWING'},
    {seq:2,value:'brandRef'},
    {seq:3,value:'BOM_DESIGN'},
    {seq:4,value:'SCROSS'},
    {seq:5,value:'source'},
    {seq:6,value:'price'},
    {seq:7,value:'sample'},
    {seq:8,value:'CLAIM'}
 
    // {seq:5,value:'forecast'}
  
  ];

  return (
    <>
      <select
        value={filterValue}
        onChange={e => {
          // if(e.target.value != 0) {
          //   setFilter(undefined);
          // }
          setFilter(e.target.value || undefined);
        }}
        className='text-danger p-1 rounded'
      >
        <option value = {''}>{<Translate k = {'step'} />}</option>
        {/* eslint-disable react/no-direct-mutation-state */}
        {steps.filter(x=>options.includes(x.value)).sort((a,b)=>a.seq-b.seq).map((step, i) => (
          <option key={i} value={step.value}>
            <Translate k = {step.value} />
          </option>
        ))}
      </select>
    </>
    
  )
}
  
const TodoTable = ()=>{

    let {data} = useSelector(state=>state.todo);

    
    data = data.filter(x=>x.type !== 'forecast');
    
    const hides = Object.keys(data[0] || {}).filter(
        key=>![
          'itemNumber',
          'type',
          'status'
      ].includes(key)
    );

    const columns =   Object.keys(data[0] || {}).filter(k=>!hides.includes(k)).map((k)=>{
      return  k === 'type' ? {
        Header:<Translate k={k}/>,
        accessor: k,
        // Cell: EditableCell,
        Cell :({cell})=><Translate k = {cell.value} />,
        Filter:SelectFilter,
        filter: 'equals'
    } : {
        Header:<Translate k={k}/>,
        accessor: k
    }
    });

    return (
      <>
         <Table columns = {columns} data = {data}  hides = {hides} /> 
      </>
        
    );   
};

export default TodoTable;