import axios from 'axios';
import React from 'react';
import {useSelector} from 'react-redux';
// import Research from "../autoline/Research";
import Loader from '../../components/Loader';
import Drawing from './Drawing';
import Product from '../../components/autoline/Product';


const SourceInput = ({row,handlePublish})=>{

     const {row:source} = row;
     
     const {_id} = row;

     const {PageOpenID} = useSelector(state=>state.userLogin);

     const [loadingFile,setLoadingFile] = React.useState(false);

     const [loading,setLoading] = React.useState(false);

     const [cancel,setCancel] = React.useState(false);

     const [business,setBusiness] = React.useState(row);
     const [price,setPrice]       = React.useState(row.row.price);

     const [changeScross,setChangeScross ] = React.useState(false);

     //@2024.08.25
    //  const [readOnly,setReadOnly] = React.useState(true);

     const [showDrawing,setShowDrawing] = React.useState(false);

     const handleShow = ()=>{
           setShowDrawing(!showDrawing);
     }

     const hiddenFileInput = React.useRef(null);
     
     const handleClick = event => {
        hiddenFileInput.current.click();
     };

     React.useEffect(()=>{

        (async()=>{
           try {
            const config = {
                headers : {
                    'Content-Type':'application/json'
                    // Authorization: `Bearer ${ userInfo.token }`
                }
            };
            setLoadingFile(true);
            //EDIT => update the record
            const URL =process.env.NODE_ENV === 'production'
            ?'https://service.eparts.cn/api/drawing'
            :'http://localhost:4000/api/drawing'; 
            
            const {data}  = await axios.post(URL,source,config);
            
            if(data.status === '201')
            {
                setBusiness({
                    ...business,
                    row:{
                        ...business.row,
                        drawingExist : true
                    }
                });   
            }
            setLoadingFile(false);

           } catch(error){
              console.log(error.message);
              setLoadingFile(false);
           }


        })();
     },[source]);

     const handleChange = async (e) => {

        try {

            if(e.target.type !== 'file')
            {   
                //   if(business.row[e.target.name] === e.target.value)
                //   {
                //     return;
                //   }
                  if(e.target.name === 'price')
                  {
                    setPrice(e.target.value);
                  }
                  
                //   if(e.target.name === 'scross')
                //   {
                //     setBusiness({
                //         ...business,
                //         row:{
                //             ...business.row,
                //             [e.target.name]:e.target.value,
              
                //         }
                //     });

                //   }
                  
    
            } else {
                const fileUploaded = e.target.files[0];

                if(!fileUploaded) return;

                const {scode,scross} = business.row;
                
                setLoadingFile(true);

                const URL =process.env.NODE_ENV === 'production'
                ?'https://service.eparts.cn/api/upload'
                :'http://localhost:4000/api/upload'; 
                
                 const formData = new FormData();

                 formData.append('file', e.target.files[0]);
                 formData.append('fileName', scode+scross);
                 formData.append('scode', scode);
                 formData.append('scross', scross);

                 const config = {
                   headers: {
                     'content-type': 'multipart/form-data',
                   },
                 };
                
                const {data} = await axios.post(
                    URL, 
                    formData, 
                    config
                );

                setLoadingFile(false);

                if(/SUCCESS/i.test(data?.msg))
                {
                    setBusiness({
                        ...business,
                        row:{
                            ...business.row,
                            drawingExist : true
                        }
                    });

                }
            }
    
        } catch (error){
            console.log(error.message);
        }

     };

     const submitHandler = async(e)=>{

          try {
            setLoading(true);
            handlePublish({
                _id,
                type:'TODO_UPDATE',
                PageOpenID,
                payload : {
                    type:'source',
                    payload:{
                        ...business,
                        row:{
                            ...business.row,
                            price
                        }
                    }
                }
            });

            setTimeout(()=>{
                setLoading(false);
            },2000);

          } catch(error){
             console.log(error.message);
          }
     };


     /*---scross update ----*/
     const handleScross = (e)=>{
          setBusiness({
             ...business,
             row:{
                 ...business.row,
                 [e.target.name]:e.target.value
             }
          })
     }

     const tagChange = ()=>{
          if(business.row.scross === '#') return;

          setChangeScross(!changeScross);
     }

     
     return (
        <>  
            {showDrawing &&  (<Drawing source = {row.row} handle = {handleShow} />)}
            {
                (business.row.scross === '#' || changeScross === false ) ? (
                 <>
                    <div className ='form-group'>
                        <label className ='text-danger d-block'>{business.row.scode}  {business.row.brand_ref} </label>
                        <label className ='text-dark'>对照号:</label>
                        <div className='input-group'>
                            <input 
                                type ='text' 
                                name ='scross' 
                                className ='form-control eparts-input'
                                value    = {business.row.scross} 
                                onChange = {handleScross}
                            />
                            <div 
                              className = 'input-group-append'
                              onClick   = {tagChange}
                            >
                                 <span className='btn btn-sm btn-outline-danger rounded'>
                                      <i className = 'fa fa-check'></i>
                                 </span>
                            </div>

                        </div>
                      
                        <span>{'...'}</span>
                    </div>
                    
                    {/* <pre>
                        {JSON.stringify(business,null,2)}
                    </pre> */}
                
                 </>
             
                ):(
                    <div className ='form-group my-2 px-3'>
                        <label 
                         onDoubleClick = {()=>setCancel(!cancel)}
                        className ='text-dark'>{row.row.scode+ ' ' +row.row.brand_ref} <span className ='ml-3'>图纸正面朝上*</span></label>
                        <div className ='input-group'>
                            <div className ='input-group-prepend'>
                                
                                {
                                    business.row?.drawingExist && !cancel ?( <span 
                                        className = {`btn btn-sm btn-outline-danger rounded`}
                                        onClick = {()=>handleShow()}
                                       
                                    >
                                        <i className ='fa fa-file-pdf-o'></i>
                                    </span>) : (
                                        <span 
                                            className ='btn btn-sm btn-outline-danger rounded'
                                            onClick   = {handleClick}
                                        >
                                            { loadingFile ?(<Loader size ='18' />):(<i className = 'fa fa-upload'></i>)}
                                        </span> 
                                    )
                                
                                }

                            </div>
                            <input 
                                type = 'text' 
                                name = 'scross' 
                                className ='form-control eparts-input'
                                placeholder = '对照号' 
                                value    = {business.row.scross}
                                readOnly = {true}
                                onDoubleClick={tagChange}
                                //  onChange = {handleChange}
                            />
                            <input 
                                type = 'text' 
                                name ='price' 
                                className ='form-control eparts-input'
                                placeholder = '价格...'
                                value    = {price}
                                onChange = {handleChange}
                            />
                            <input 
                                type = 'file' 
                                name ='file' 
                                className ='form-control eparts-input d-none' 
                                onChange={handleChange}
                                ref     ={hiddenFileInput}
                            />
                            
                            {
                            price > 0 && (
                                    <div className ='input-group-append'>
                                        {/* <span 
                                        className ='btn btn-sm btn-outline-danger rounded'
                                        onClick   = {handleClick}
                                        >
                                            { loading ?(<Loader size ='18' />):(<i className = 'fa fa-upload'></i>)}
                                        </span> */}
            
                                        <span 
                                        className ='btn btn-sm btn-outline-danger rounded'
                                        onClick   = { submitHandler }
                                        >
                                        {loading ? <Loader size='18' /> :(<i className = 'fa fa-save'></i>)} 
                                        </span>
                                    </div>
                                )
                            }
                        
                        </div>
                    </div>
                )
            }
            

            {/* <div className ='form-group'>
                <pre>{JSON.stringify(row,null,2)}</pre>
            </div> */}
        </>
    
     )
}

const Source = ({row,handlePublish})=>{

    const {_id,from,manager,type,data,status} = row.original;
    const {userInfo} = useSelector(state=>state.userLogin);
 
    return (

       <div className ='row'>
          
     
          {userInfo?.role_type ==='STAFF' && (
            <> 

           <div className ='col-md-6'>
              {/* <Research oeBrandRef={ row.original.itemNumber} /> */}
              <Product refNumber = {row.original.itemNumber}/>
              {/* <pre>
                {JSON.stringify(row.original,null,2)}
              </pre> */}
           </div>
           <div className ='col-md-6'>
                {
                     data.map(x=> {return {_id,from,manager,type,status,...x}}).map((source,i)=>{

                          return (
                            <SourceInput 
                              key = {i} 
                              row = {{...source,_id}}
                              handlePublish = {handlePublish} 
                            />
                          )
                     })
                }
           </div>
            
            </>
          )  }

          {userInfo?.role_type === 'VENDOR' && ( <div className ='p-3 mx-auto'>
                {
                     data.map(x=> {return {_id,from,manager,type,status,...x}}).map((source,i)=>{

                          return (
                            <SourceInput 
                              key = {i} 
                              row = {{...source,_id}}
                              handlePublish = {handlePublish} 
                            />
                          )
                     })
                }
           </div>)}
     
          
       </div>
    )
}

export default Source;