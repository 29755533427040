import React,{useEffect} from 'react';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import BarcodeLogin from '../components/BarcodeLogin';
import { Row } from 'react-bootstrap';
import nsk from '../images/NSK.png';

import DebugLogin from '../components/DebugLogin';

const Login = ()=>{
     
    const {task}     = useSelector(state=>state.task);
    const {userInfo}   = useSelector(state=>state.userLogin);
    const {data}       = useSelector(state=>state.todo);

    const todos = data.filter(x=>[x.from,x.manager].includes(userInfo.open_id));

    let openTodos = [];

    // console.log('openTodos',openTodos);

    data.forEach(todo=>{
       todo.data.forEach(x=>{
           if(x.to === userInfo.open_id)
           {
               openTodos = [...openTodos,todo];
           }
       });
    });
 
    let   redirect = '/login';

    if(['STAFF','AGENT'].includes(userInfo?.role_type))
    {
        redirect = '/admin';
    } else if (['IMPORTER'].includes(userInfo?.role_type)){
        redirect = '/forecast';
    } 
    else {
        if(task?.length > 0) 
        {
            redirect = '/task';
        } else if (openTodos?.length > 0 && userInfo?.open_id)
        {  
            redirect = `/subscribe/${userInfo.open_id}::${openTodos[0]._id}`
        }
    }
 
    const navigate = useNavigate();

    useEffect(()=>{
        navigate('/');
    },[]);
 
    useEffect(()=>{
        if(redirect !== '/login')
        {   
            navigate(redirect);
        }
      
    },[navigate,redirect,userInfo,task,todos,openTodos]);

    return (
        <>
            {process.env.NODE_ENV !== 'production' && <DebugLogin />}
            <Row className='mb-6'>
                <BarcodeLogin bu={'ZJQ'} />    
            </Row>
          
            <section>
                <div className = 'row text-center'>
                    <span className ='mx-auto'>
                        <img 
                            src = {nsk} 
                            width ='80'
                            alt = 'NSK' 
                            
                        />
                        <span className ='ml-2'>
                            订货服务提供商
                        </span>
                    </span>
                </div>
                <div 
                    style ={{
                        marginTop:'5vh',
                        minHeight:'150px',
                        backgroundImage:'url('+require('../images/pallet.jpg')+')',
                        backgroundPosition: 'center',
                        backgroundSize: '70%',
                        backgroundRepeat: 'no-repeat',
                    }}>
                    ...
                </div>
            </section>
        </>
    );
};
export default Login;