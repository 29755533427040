import { INPUT_UPDATE } from "../constants/inputConstants";


const inputReducer = (state={value:''},action) =>{
     switch (action.type)
     {  
         case INPUT_UPDATE:
            
            return {value:action.payload.payload.input};
         default:
            return state;
     }
};

export default inputReducer;
