import React from 'react';
import ReactDOM from 'react-dom/client';
import './normalize.css';
import './bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';
import './index.css'; 

//language
import LanguageProvider from './contexts/LanguageProvider';

//redux
import { Provider }  from 'react-redux';
import store from './redux/store';
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('appRoot'));

root.render(
  <Provider store = {store}>
      <LanguageProvider>
          <App />
      </LanguageProvider>
  </Provider>
);

