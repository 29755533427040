

const Payment = ()=>{


    return (
        <p>payment...</p>
    )
};

export default Payment;