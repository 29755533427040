
import axios from 'axios';
import React from 'react';

import {  Table } from "react-bootstrap";
import Loader from './Loader';
import Message from './Message';
//toIndex
const BomList = ({row})=>{
   
    const [loading,setLoading] = React.useState(false);
    const [err,setErr] = React.useState('');
    const [bom,setBom] = React.useState([]);


    React.useEffect(()=>{
       
        (async()=>{

            try {
                
                if(bom?.length > 0) return;

                setLoading(true);
        
                //http_request
                const config = {
                    headers : {
                       'Content-Type':'application/json'
                       // Authorization: `Bearer ${ userInfo.token }`
                    }
                };
                
                //EDIT => update the record
                const URL =process.env.NODE_ENV === 'production'
                ?'https://service.eparts.cn/api/bom'
                :'http://localhost:4000/api/bom'; 
              
         
                const {data}  = await axios.post(URL,{item_code:row.item_code},config);
                // console.log('response',data);

                if(/ERROR/.test(data.errMsg)){
                    throw new Error(data.msg);
                }

                setBom(data.bom);
                setLoading(false); 

            } catch(error){
                setErr(error.message);     
            }
           

        })();

    },[]);

    return (
      <>
         {loading && <Loader />}
         {err && <Message>{err}</Message>}
         {bom?.length > 0 && (
            <>
               <div className ='eparts-row'>                   
                <div className='col-md-8 col-xs-12 mx-auto'>
                    <h6 className ='mx-auto text-danger p-2 my-3'
                    >{`BOM LIST | ${row.ref_no}`}</h6>
                    <Table>
                        <thead>
                            <tr>
                                <th>PART_NO</th>
                                <th>DESCRIPTION</th>
                                <th>QTY</th>
                                <th>vendor</th>
                                {/* <th>action</th> */}
                            </tr>
                        </thead>
                        <tbody>
                            {
                            bom?.map(r=>{
                                    return ( 
                                        <tr key ={r.uuid}>
                                            <td>{r.part_no}</td>
                                            <td>{r.description ? r.description :r.物料描述}</td>
                                            <td>{r.qty}</td>
                                            <td>{r.base}</td>
                                            {/* <td>
                                                {
                                                    r.base ?(   
                                                    <span className ='btn btn-sm btn-outline-secondary rounded'>
                                                        <i className ='fa fa-edit' style = {{fontSize:'120%'}}></i>
                                                    </span>) 
                                                    :(
                                                    <span 
                                                        className ='btn btn-sm btn-outline-danger rounded'
                                                        onClick = {()=>alert('edit the toIndex drawing')}
                                                    >
                                                        <i className ='fa fa-edit' style = {{fontSize:'120%'}}></i>
                                                    </span>
                                                )
                                                }
                                            </td> */}
                                        </tr>
                                    );
                                })
                            }
                        </tbody>

                    </Table>
                </div>
               </div>
            </>
         )}
      </>
      
    )
};

export default BomList;