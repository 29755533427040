
import React from 'react';

const Burger = ({id,children})=>{
     
    const [hide,setHide] = React.useState(false);

    let cls = !hide ? '' :'d-none';

    return (
       <>
         <div className='container shadow pb-3'>
            {/* <div className='eparts-row'> */}
                <span 
                    className = 'text-danger btn btn-sm btn-outline-secondary border-0 rounded'
                    style = {{fontSize:'70%'}}
                    onClick = {()=>setHide(!hide)}
                ><i className='fa fa-bars'></i> {id?id:''}
                </span>
            {/* </div> */}
            <div className ={`mt-1 mb-2 ${cls}`}>

              {children}
                {/* <p>from burger</p> */}
                {/* <div className='p-3'>
                
                </div> */}
            </div>
         </div>
      
       </>

    );
};

export default Burger;