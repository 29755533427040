import { useSelector } from "react-redux";

const ShowPhoto = ({row})=>{

    const {uploads} = useSelector(state=>state.upload);

    const upload = uploads.find(x=>x.uuid === row.uuid);

    return (
        <>
           
           {upload && (
               <div className = 'eparts-row mt-2'>
                    <img src = {upload.base64}  width ='280' alt = 'photo' />
               </div>
           )}
        </>
       
    )
}

export default ShowPhoto;