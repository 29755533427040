import axios from 'axios';
import React from 'react';
import Research from "../autoline/Research";
import Loader from '../../components/Loader';
import { Modal } from 'react-bootstrap';
import CloseBtn from '../../components/CloseBtn';

const Drawing = ({source,handle})=>{
    const [loading,setLoading] = React.useState(false);
    const [show,setShow] = React.useState(true);
    const [file,setFile] = React.useState('');

    

    React.useEffect(()=>{
       
        (async()=>{

            try {
                
                setLoading(true);
                //http_request
                const config = {
                    headers : {
                       'Content-Type':'application/json'
                       // Authorization: `Bearer ${ userInfo.token }`
                    }
                };
                
                //EDIT => update the record
                const URL =process.env.NODE_ENV === 'production'
                ?'https://service.eparts.cn/api/drawing'
                :'http://localhost:4000/api/drawing'; 
              
         
                const {data}  = await axios.post(URL,{...source},config);
                // console.log('response',data);
                setLoading(false);

                if(/ERROR/.test(data.errMsg)){
                    throw new Error(data.msg);
                }
                
                setFile(data.msg);
        
            } catch(error){
                // setErr(error.message);     
            }
           

        })();

    },[source]);

    const handleClose = ()=>{
          setShow(false);
          handle();
    }
     

    return (
        <Modal show={show} onHide={handleClose} className='modal-template'>
          <Modal.Header>
            <h4>DRAWING - {source.scross}</h4>
            <CloseBtn onClick ={handleClose} />
          </Modal.Header>
          <Modal.Body className='dwg-body'>
             {loading && (<Loader />)}
             {/* <pre>{JSON.stringify(source,null,2)}</pre> */}
             {/* {data && data.length >0 && (<PreEcho obj = {data} />)} */}
             {file && (<embed src ={`data:application/pdf;base64,${file}`} className='dwg-embed'/>)}
          </Modal.Body>
        </Modal>
    );
}

const Index = ({oeBrandRef,selectHandler,row,handlePublish})=>{
    
    const [items,setItems] = React.useState([]);
    // const [loading,setLoading] = React.useState(false);
    const [showDrawing,setShowDrawing] = React.useState(false);

    const [check,setCheck]             = React.useState(false);

    const handle = ()=>{
        setShowDrawing(!showDrawing);
    }

    React.useEffect(()=>{

        (async()=>{

            try {
                // setLoading(true);
                   //http_request
                const config = {
                    headers : {
                       'Content-Type':'application/json'
                       // Authorization: `Bearer ${ userInfo.token }`
                    }
                };
                
                //EDIT => update the record
                const URL =process.env.NODE_ENV === 'production'
                ?'https://service.eparts.cn/api/source'
                :'http://localhost:4000/api/source'; 
              
                const {data:{source,errMsg}}  = await axios.post(URL,{oeBrandRef},config);

                if(source)
                {
                    setItems(source);
                }

            } catch(error){

                console.log(error.message);
            }
        })();
    },[oeBrandRef]);

    const appendTodo = async (source)=>{
         
         try {

             /*
               
                  //     if(!$sourceExist)
                    //     {  
                    //         $todo->docNumber ='#';
                    //         $todo->itemNumber = $item->refs[0];
                    //         $todo->type = 'source';
                    //         $todo->payload->to = $scode;
                    //         $todo->payload->tableName = '供应链管理.source';
                    //         $todo->payload->status    = 'O';
                    //         $todo->payload->cost      =  0;
                    //         $todo->payload->cur       = 'RMB';
                            
                    //         $todo->payload->row       = new stdClass();

                    //         $todo->payload->row->uuid      =  uuid();
                    //         $todo->payload->row->brand_ref = $item->refs[0];
                    //         $todo->payload->row->brand     = auth_brandref($item->refs[0]);
                    //         $todo->payload->row->scode     = $scode;
                    //         $todo->payload->row->scross    = '#';
                    //         $todo->payload->row->price     = 0;
                    //         $todo->payload->create_date    =  date('Y-m-d');

                    //         todo($todo);

                    //         // get_redis()->publish('TODO:'.$_POST['from'],json_encode_cn($todo));

            
                    //     }

             */
             
            //  console.log('business row for append',row);

             const {from,catalogID,docNumber,itemNumber,type,payload,...rest} = row;

             handlePublish({
                type:'TODO_APPEND',
                payload:{
                    from,
                    catalogID,
                    docNumber:'#',
                    itemNumber,
                    type:'source',
                    payload:{
                        to:source.scode,
                        tableName:'供应链管理.source',
                        status:'O',
                        cost:0,
                        cur: 'RMB',
                        row: source
                    }
                }
            
            });

         } catch (error){

             console.log(error.message);

         }


    }

    const regiserSourceHandler = async(source)=>{
          try {

             const {uuid} = source;

             const config = {
                headers : {
                   'Content-Type':'application/json'
                   // Authorization: `Bearer ${ userInfo.token }`
                }
            };
            
            //EDIT => update the record
            const URL =process.env.NODE_ENV === 'production'
            ?'https://service.eparts.cn/api/register_source'
            :'http://localhost:4000/api/register_source'; 
          
            const {data:{msg,errMsg}}  = await axios.post(URL,{uuid},config);
             
            if(/SUCCESS/i.test(msg))
            {
                  console.log('registered');
                  setCheck(true);
            }

          } catch (error){

             console.log(error.message);
                 setCheck(false);
          }

    }

    
    return (
        <> 
          {/* {loading && <Loader size = '30' />}  */}
          {showDrawing && (<Drawing source = {items[0]} handle = {handle} />)}
          {
             items?.length > 0 && items.map((item,i)=>{
                  return (
                    <>
                        <div className ='form-group' key = {i}>
                            <label className='d-block text-danger'>{item.brand_ref} {check && (<i className='fa fa-check ml-2 text-danger'></i>)}</label>
                            {/* <span className ='btn btn-sm btn-outline-dark rounded border-0'>{item.brand_ref}</span> */}
                            <div>
                                <span 
                                    className = {
                                        `btn btn-sm btn-outline-${/SUCCESS/i.test(item.drawing) ? 
                                        'warning':
                                        'secondary'}  rounded`
                                    }
                                    onClick = {()=>{

                                         if(!/SUCCESS/.test(item.drawing)) 
                                         {
                                            // return appendTodo(item);
                                            return regiserSourceHandler(item);
                                        
                                         }

                                         setShowDrawing(true);
                                    }}
                                >
                                    <i className = 'fa fa-file-pdf-o'></i>
                                </span>
                                <span 
                                    className={`
                                    btn  
                                    btn-sm 
                                    btn-outline-${/SUCCESS/i.test(item.drawing) ? 
                                    'warning':
                                    'secondary'} 
                                    rounded`}
                                    onClick = {()=>{
                                        if(!/SUCCESS/i.test(item.drawing)) return alert('NO DRAWING');
                                        selectHandler(item.uuid)
                                    }}
                                >
                                <i className = 'fa fa-flag-o'></i>
                                </span>
                                <span className ='btn btn-sm btn-outline-dark rounded border-0'>{item.scode}</span>
                                <span className ='btn btn-sm btn-outline-dark rounded border-0'>{item.scross}</span>
                                <span className ='btn btn-sm btn-outline-dark rounded border-0'>{item.price}</span>
                            </div>
                        </div>
                    </>
                  );
             })
          }     
       
        </>
    )
}

const Price = ({row,handlePublish})=>{

    const [loading,setLoading] = React.useState(false);
 
    const {_id,type} = row;

    // console.log('the row',row);

    const [business,setBusiness] = React.useState({
        _id,
        type:'TODO_UPDATE',
        payload:{
            type,
            payload:row.data[0].row
        }
    });

    // console.log(business);

    const [selected,setSelected] = React.useState('#');

    const changeHandler = (e)=>{

        //   return;

          setBusiness({
              ...business,
              payload:{
                  ...business.payload,
                  payload:{
                    ...business.payload.payload,
                    [e.target.name]:e.target.value
                  }
              }
          });
    }

    const sourceHandler = (srcId)=>{
            // return;
        
            const {sourceID} = business.payload.payload;
            
            setSelected(srcId === sourceID ? '#':srcId);
            
            setBusiness({
                ...business,
                payload:{
                    ...business.payload,
                    payload:{
                        ...business.payload.payload,
                        sourceID  : sourceID ? '' : srcId
                    }
                }
            });
          
    }

    return (
        <div className ='row'>
            <div className ='col-md-6'>
                <Research oeBrandRef={ row.data[0].row.refs[0]} />
                {/* <pre>
                    {JSON.stringify(business,null,2)}
                </pre> */}
            </div>
            <div className ='col-md-6'>
                 <div className ='card'>
                      <div className ='card-header'>
                           <div className ='form-group'>
                               <label className ='text-danger'>
                                    <b>
                                        {business.payload.payload.item_code + '/' + business.payload.payload.brand_ref}
                                    </b>
                                    <span className ='ml-3 text-dark'>
                                          {business.payload.payload.source.find(x=>x.uuid === selected)?.scode}
                                    </span>
                               </label>
                               <div className = 'input-group'>
                                 <div className ='input-group-prepend'>
                                     <span className ='btn btn-sm btn-outline-danger rounded'>
                                         $
                                     </span>
                                 </div>
                                 <input 
                                   type = 'number' 
                                   name = 'price' 
                                   className ='form-control eparts-input'
                                   value = {business.payload.payload.price}
                                   onChange = {changeHandler}
                                />
                                <div className ='input-group-append'>
                                     {
                                        business.payload.payload?.sourceID && (
                                            <span 
                                                className = {`btn btn-sm btn-outline-danger rounded`}
                                                onClick   = {()=>{
                                                    setLoading(true);
                                                    handlePublish(business);
                                                    setTimeout(()=>{
                                                        setLoading(false);
                                                    },2000);
                                                }}
                                            >
                                                {loading ? (<Loader size ='18' />):(<i 
                                                    className ='fa fa-paper-plane'
                                                >
                                                </i>)}
                                            </span>
                                        )
                                     }
                                     
                                </div>
                              </div>
                           </div>
                        
                      </div>
                      <div className ='card-body'>
                          
                            {
                               business.payload.payload.refs.map((item,i)=>{
                                   return (
                                       <Index 
                                          oeBrandRef={ item }

                                          selectHandler={sourceHandler} 

                                          handlePublish = {handlePublish}

                                          row = {row}

                                          key = {i} 
                                        
                                        />
                                   )
                               })
                            }  
                      </div>
                 </div>
            </div>
        </div>
    );
};

export default Price;