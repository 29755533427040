import axios from 'axios';
import React from 'react';
import { Modal } from 'react-bootstrap';
import Loader from '../../components/Loader';
import CloseBtn from '../../components/CloseBtn';


const Drawing = ({source,handle})=>{

    const [loading,setLoading] = React.useState(false);
    const [show,setShow] = React.useState(true);
    const [file,setFile] = React.useState('');


    React.useEffect(()=>{
       
        (async()=>{

            try {
                
                setLoading(true);
                //http_request
                const config = {
                    headers : {
                       'Content-Type':'application/json'
                       // Authorization: `Bearer ${ userInfo.token }`
                    }
                };
                
                //EDIT => update the record
                const URL =process.env.NODE_ENV === 'production'
                ?'https://service.eparts.cn/api/drawing'
                :'http://localhost:4000/api/drawing'; 
              
         
                const {data}  = await axios.post(URL,{...source},config);
                // console.log('response',data);
                setLoading(false);

                if(/ERROR/.test(data.errMsg)){
                    throw new Error(data.msg);
                }
                
                setFile(data.msg);
        
            } catch(error){
                // setErr(error.message);     
            }
           

        })();

    },[source]);

    const handleClose = ()=>{
          setShow(false);
          handle();
    }
     

    return (
        <Modal show={show} onHide={handleClose} className='modal-template'>
          <Modal.Header>
            <h4>DRAWING - {source.scross}</h4>
            <CloseBtn onClick ={handleClose} />
          </Modal.Header>
          <Modal.Body className='dwg-body'>
             {loading && (<Loader />)}
             {/* <pre>{JSON.stringify(source,null,2)}</pre> */}
             {/* {data && data.length >0 && (<PreEcho obj = {data} />)} */}
             {file && (<embed src ={`data:application/pdf;base64,${file}`} className='dwg-embed'/>)}
          </Modal.Body>
        </Modal>
    );
};

export default Drawing;
