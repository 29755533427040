import { PRODUCT_SOURCE, SOURCE_ERR_MSG, SOURCE_FETCH,SOURCE_UPDATE, } from "../constants/sourceConstants";

const sourceReducer = (state = {source:[],errMsg:[],product:[]},action)=>{
    switch(action.type) {
        case SOURCE_FETCH:
           return {...state,source:action.payload};
        case SOURCE_UPDATE:
           return {
            ...state,
            source:[
               ...state.source.filter(x=>x.uuid !== action.payload.uuid),
               action.payload
            ]
           };
        case SOURCE_ERR_MSG:
           return {
              ...state,
              errMsg:[
                  ...state.errMsg.filter(x=>x.uuid !== action.payload.uuid),
                  action.payload
              ]
           };
      //   case PRODUCT_SOURCE:
      //    console.log('payload',action.payload);
      //     return {
      //        ...state,
      //        product:[
      //            ...state.product.filter(x=>x._id !== action.payload._id),
      //            action.payload
      //        ]
      //     };

        default:
           return state;
    }

};

export default sourceReducer;