import React from 'react';
import { Spinner } from 'react-bootstrap';

const Loader = ({size,msg=''}) => {

    if(!size){
        size = 100;
    }
    // console.log(msg);
    if(!/px$/.test(size)){
        size +='px';
    }
    return (
        <Spinner 
            animation = 'border' 
            role = 'status' 
            // className = {className}
            style = {{ width: size,height : size,margin :'auto',display:'block'}}
        >
            <span className = {`sr-only`}>{msg }</span>
            {/* <p>{msg.msg}</p> */}
        </Spinner>
    );
}
export default Loader;
