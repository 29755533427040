import React from 'react';
import {useSelector} from 'react-redux';
// import PreEcho from '../PreEcho';
import classNames from 'classnames';
import ImageBox from './ImageBox';
function MessageBubble({data}) {
  // const {open_id:currentUser} = useSelector(state=>state.userLogin)?.userInfo;
  let {PageOpenID:currentUser,userInfo} = useSelector(state=>state.userLogin);

  currentUser = sessionStorage.getItem('PageOpenID') ?  sessionStorage.getItem('PageOpenID') :currentUser;

  const align  = data.from === currentUser ? 'align-self-end' : 'align-self-start';
  const bg     = data.from !== currentUser ? 'bg-secondary shadow' : 'bg-green shadow';
  const margin = data.from !== currentUser ? 'ms-3' : 'me-3';

  return (
    <div
       className = {
        classNames(
        'ezychat-message-bubble--container px-2 py-1 my-1',
         align,
         bg,
         margin
       )}
    >
      <> 
         <label sytle={{fontSize:'70%'}}>{data.createDate}|<b className = 'text-danger'>{data.MsgType}</b>
         {/* |<span className='text-dark'>{data.MsgTag}</span> */}
          <span className ='ml-2 text-info'>
            {data.status === 'T' && data.from !== currentUser && (data.read ? (<i className='fa fa-check' style={{fontSize:'80%'}}></i>) :(<i className='fa fa-sticky-note-o text-danger'></i>))}
          </span>
         </label>
         {data.MsgType !== 'image' 
            ? (<div style ={{fontSize:'105%'}} className ={data.from === currentUser?'text-right':''}><b>{data.message}</b></div>)
            :(<ImageBox row = {data} />)}
      </>
    </div>
  );
}

export default MessageBubble;
