import { TO_BUY_ORDER, TO_BUY_SUCCESS, TO_BUY_UPDATE } from "../constants/toBuyConstants";

const toBuyReducer = (state={data:[]},action)=>{
      switch (action.type)
      {
         case TO_BUY_SUCCESS:
            return {...state,data:action.payload};
         case TO_BUY_UPDATE:
            return {...state,data:state.data.map(t=>t.uuid === action.payload.uuid ? action.payload :t)};
         case TO_BUY_ORDER:
            console.log(action);
            return {...state}
         default:
            return state;
      }

};
export default toBuyReducer;