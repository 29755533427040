
import React from 'react';
import {useSelector} from 'react-redux';
import Loader from '../../components/Loader';

const ShowPhoto = ({row})=>{
    const {uploads} = useSelector(state=>state.upload);

    const upload = uploads.find(x=>x.uuid === row.uuid);

    return (
        <>
           
           {upload && (
               <div className = 'eparts-row mt-2'>
                    <img src = {upload.base64}  width ='280' alt = 'photo' />
               </div>
           )}
        </>
       
    )
}
const TakePhoto = ({row,handlePublish})=>{

    const hiddenInput = React.useRef(null);

    const {userInfo} = useSelector(state=>state.userLogin);

    const [business,setBusines] = React.useState(row.data[0].row);

    const [file,setFile] = React.useState('');

    const [err,setErr]   = React.useState('');

    const [loading,setLoading] = React.useState(false);

    const {uploads} = useSelector(state=>state.upload);

    const upload = uploads.find(x=>x.uuid === row.uuid);

    React.useEffect(()=>{
        if(upload)
        {
            setLoading(false);
        }
        //publish to get file

    },[upload]);


    React.useEffect(()=>{
          
        if(userInfo.open_id)
        {
            handlePublish({
                _id:row._id,
                type:'TODO_UPDATE',
                from:userInfo?.open_id,
                status:'T',
                payload : {
                    type:'PHOTO',
                    status:'O',
                    payload:{
                        ...business,
                    } 
                }
            });

        }
       

    },[userInfo]);

    function convertToBase64(file) {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => resolve(reader.result);
          reader.onerror = error => reject(error);
        });
    }

    function msgBox(msg)
    {
         setErr(msg);
         setTimeout(function(){
            setErr('');
         },3000);
    }

    const changeHandler = async (e)=>{
        // setFile(URL.createObjectURL(e.target.files[0]));
        setFile(e.target.files[0]);
   
        try {

            const file = e.target.files[0];

            if(!file)
            {
                throw new Error('NO FILE FOUND');
            }

              // Check file size (in bytes)
            const maxSizeInBytes = 1 * 1024 * 1024; // 2MB

            if(file.size > maxSizeInBytes)
            {
                // return msgBox('图片尺寸过大');
            }

            setLoading(true);

            const base64String = await convertToBase64(file);

            setFile(base64String);

            handlePublish({
                _id:row._id,
                type:'TODO_UPDATE',
                from:userInfo?.open_id,
                payload : {
                    type:'PHOTO',
                    status:'O',
                    payload:{
                        ...business,
                        file:base64String
                    } 
                }
            });

            setTimeout(function(){
                setLoading(false);
            },3000);

        } catch (error){

            console.log(error.message);
        }

    }

    // const submitHandler = (e)=>{

    //     if(!file) return;

    //     // if(window.confirm('are you sure to upload: ?'))
    //     // {
    //     //     console.log('the file',file);
    //     //     handlePublish({
    //     //         _id:row._id,
    //     //         type:'TODO_UPDATE',
    //     //         payload : {
    //     //             type:'PHOTO',
    //     //             status:'O',
    //     //             payload:{
    //     //                 ...business,
    //     //                 file
    //     //             } 
    //     //         }
    //     //     });
    //     // }
    // }
    
    return (
        <div className ='eparts-row'>
             <div className ='col-md-6 col-xs-12 mx-auto'>
                  <div className = 'form-group'>
                      <label className='text-danger'>{err}</label>
                      <div className = 'input-group'>
                          <div className ='input-group-prepend'>
                              <span 
                                 className ='btn btn-sm btn-outline-danger rounded'
                                 onClick = {()=>hiddenInput.current.click()}
                              >
                                 <i className ='fa fa-upload'></i>
                              </span>
                          </div>
                          <input 
                            type = 'file' 
                            accept='image/*' 
                            className ='form-control eparts-input d-none'
                            onChange = {changeHandler}
                            style = {{maxWidth:'280px'}}
                            ref = {hiddenInput}

                          />
                         
                          {
                            loading && (
                                <div className ='input-group-append'>
                                    <span 
                                    className ='btn btn-sm btn-outline-danger rounded'
                                    //  onClick = {submitHandler}
                                    >
                                    <Loader size ='16' />
                                    </span>
                                </div>
                            )
                          }
                        
                      </div>
                      <ShowPhoto row  = {business} />
                      {/* <div>
                         { file && <img src = {file} width ='325'/> }
                      </div> */}
                  </div>
             </div>
        </div>
    )
}

export default TakePhoto;