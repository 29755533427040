import WechatPayment from "../WechatPayment";


const MessageHeader = ({PID})=>{
   return (
    <div className="rounded-0 shadow header-height" >
        <div className="d-flex flex-row bg-white p-2 justify-content-between">
            <div className="d-flex align-items-center">
                {
                PID ? (
                    <div className ='btn-group'>
                            <span className="rounded" >
                            <i className='fa fa-flag mr-2 text-danger'></i> <span style ={{borderBottom:'3px double #C4261D',paddingBottom:'5px'}}>{PID}</span>
                            </span> 
                            {/* <span className ='ml-4'><WechatPayment /></span> */}
                            {/* <span className='btn btn-sm btn-outline-warning'>VENDOR</span>  
                            <span className='btn btn-sm btn-outline-info'>VS3</span>   */}
                    </div>
                    )
                    : (
                        <button type="button" className="btn btn-danger rounded-circle border-0 w-40px h-40px">
                            <i className="fa fa-user" />
                        </button>
                    )
                }
            </div>
        </div>
    </div>
   );
};

export default MessageHeader;