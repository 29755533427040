import { Container ,Row,Col } from 'react-bootstrap';
const Footer = () => {
    return (
        <footer className='epartsFooter' id='footer'>
               <Container >
                  <Row>
                       <Col className = 'text-center my-2'>Copyright &copy; EPARTS|宜配驰 <span className='text-info'>V1.0.1</span></Col>
                  </Row>
               </Container>
        </footer>
    );
}

export default Footer;