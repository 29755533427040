import React from 'react';
import { useNavigate } from 'react-router-dom';
import {useSelector} from 'react-redux';
import ForecastTable from '../tables/forecast/ForecastTable';

const Forecast = () =>{
    const navigate = useNavigate();
    const {userInfo,userInfo:{role_type}} = useSelector(state=>state.userLogin);
    const {data}  = useSelector(state=>state.todo);
    
    React.useEffect(()=>{
        //todo as prioirty
        if(data?.filter(x=>x.type !== 'forecast').length> 0 || userInfo?.role_type !== 'IMPORTER')
        {
             navigate('/');
        }
        // if(role_type !== 'IMPORTER')
        // {
        //     navigate('/login');
        // }
       
    },[data,userInfo]);
    return (
        <>
          {role_type === 'IMPORTER' && (<ForecastTable />)}  
        </>
    )
}

export default Forecast;