import { RESEARCH_FETCH, RESEARCH_SEARCH, RESEARCH_UPDATE } from "../constants/researchConstants";
export const researchReducer = (state = {research:[],search:[]},action) => {
    switch (action.type){
        case RESEARCH_FETCH:
           return {...state,research:action.payload};
        case RESEARCH_UPDATE:
           return {...state,research:state.research.map(r=>r.uuid === action.payload.uuid ? action.payload : r)};
        case RESEARCH_SEARCH:
           //sync the redis search array
           return {...state,search:[...action.payload]};
        default:
           return state;
    }
}