import React from 'react';
import {useSelector} from 'react-redux'
import { useTable,useRowState,useFilters,useGlobalFilter,useExpanded,usePagination} from 'react-table';
// import useTableState from '../../hooks/useTableState';
import Translate from '../../components/Translate';
import BTable from 'react-bootstrap/Table';
import Row from 'react-bootstrap/Row';
import LanguageContext from '../../contexts/contexts';
import GlobalFilter from '../../components/GlobalFilter';

import EditableCell from '../../components/EditableCell';
import RenderDrawing from '../../components/RenderDrawing';
import Loader from '../../components/Loader';
import BomList from '../../components/BomList';
import { Badge } from 'react-bootstrap';
import { publish } from '../../utils/socket';


function SelectFilter({column:{filterValue,setFilter, preFilteredRows, id}}) {
    // console.log('column',column);
    // const options = [0]; //Open,Complete


    const options = React.useMemo(() => {
      const options = new Set();
      preFilteredRows.forEach(row => {
        options.add(row.values[id])
      })
      return [...options.values()].sort();
    }, [id, preFilteredRows]);

    return (
      <>
        <select
          value={filterValue}
          onChange={e => {
            // if(e.target.value != 0) {
            //   setFilter(undefined);
            // }
            setFilter(e.target.value || undefined);
          }}
          className='text-danger p-1 rounded'
        >
          <option value = {''}>FY</option>
          {options.map((option, i) => (
            <option key={i} value={option}>
              {option}
            </option>
          ))}
        </select>
      </>
      
    )
}


function Table({ columns, data, updateMyData, 
  skipPageReset,autoResetGlobalFilter,hides}) 
{

    const {language} = React.useContext(LanguageContext); 
   

    const tableInstance = useTable(
      {
        columns,
        data,
        updateMyData, 
        autoResetPage:!skipPageReset,
        autoResetGlobalFilter:!autoResetGlobalFilter,
        initialState:{hiddenColumns:hides,pageSize:5,pageIndex:0,tableName:'供应链管理.forecast'}
      },
      useRowState,
      hooks=>{
         hooks.visibleColumns.push(columns=>[
           {
            id:'BOM',
            Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }) => (
              <span {...getToggleAllRowsExpandedProps()} className ='text-danger'>
                {isAllRowsExpanded ? '...' : ('BOM')}
              </span>
            ),
            // accessor:k,
            // ...getWidth(k),
            maxWidth: 8,
            minWidth: 5,
            width: 5,
            Cell: ({ row }) => {
             return (
              <span className='text-danger' {...row.getToggleRowExpandedProps()} >
                {row.isExpanded ? (<i className='fa fa-minus'></i>) : (<i className='fa fa-plus'></i>)}
              </span>
            )}
           },
           ...columns,
    
          {
            id:'drawing',
            Header:<Translate k= {'drawing'} />,
            maxWidth: 8,
            minWidth: 5,
            width: 5,
            Cell:RenderDrawing
          },
          // {
          //   id:'forecast',
          //   Header:<Translate k={'forecast'} />,
          //   maxWidth: 8,
          //   minWidth: 5,
          //   width: 5,
          //   Cell:(props)=>{
          //      const cls = props.cell.row.original.qty ? 'secondary':'danger';
          //      return (
          //        <span 
          //          className ={`btn btn-sm btn-outline-${cls} rounded`}
          //         ><i className="fa fa-list-alt"></i>
          //        </span>
          //      )
          //   }
          // }
         ]);
      },
      useFilters, 
      useGlobalFilter,
      useExpanded,
      usePagination,
    //   useTableState //log the state
    );
    // console.log(tableInstance);
    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      page, //instead of rows
      prepareRow,
      visibleColumns,
      state: { globalFilter,pageIndex},
      preGlobalFilteredRows,
      setGlobalFilter,
      //page
      canPreviousPage,
      canNextPage,
      pageOptions,
      pageCount,
      gotoPage,
      nextPage,
      previousPage
    } = tableInstance;

    // Render the UI for your table
    return (
      <>
      {/* {isDev() && <button onClick ={()=>testDispatch()}>TEST DISPATCH</button>} */}
      <GlobalFilter 
        preGlobalFilteredRows={preGlobalFilteredRows} 
        globalFilter={globalFilter} 
        setGlobalFilter={setGlobalFilter}
        language={language}
        // show = {key === 'main'}
      />
      {/* <SelectFilter setFilter={setFilter} /> */}
      {/* <Switch /> */}

      {/* {show && <Todo />} */}
      {/*---inject oringial---*/}
      {/* {dwg && (<ShowDrawing rowData ={dwg} handle = {handleClose}/>)} */}

      <BTable  bordered size="sm"  { ...getTableProps()}>
          <thead>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => {
                  let hideColumn = column.id === 'item_code' ? 'hideColumn' :'';
                  // console.log(column);
                  return (<th {...column.getHeaderProps({
                    className:`${hideColumn}`,
                    style:{ minWidth: column.minWidth, width: column.width }})}>
                      {/* {column.render('Header')} */}

                      {column.canFilter && column.id === 'qty' ? column.render('Filter') : column.render('Header')}
                      {/* <div>{column.canFilter ? column.render('Filter') : null}</div> */}
                    </th>);
                })}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              const rowProps = row.getRowProps();
              return (
              <React.Fragment key={rowProps.key}>
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell,k) => {
                      let hideColumn = cell?.column.id === 'item_code' ?'hideColumn':'';
                    
                      //cell.setState,set the value,@20230615
                      //  onDoubleClick={()=>cell.setState(attachTodoID(cell?.column.id))}
                      return (<td key = {k} {...cell.getCellProps({className:`${hideColumn}`})} style = {{padding:'6px'}}>
                               {cell.render('Cell')}
                             </td>)
                    })}
                  </tr>
                  {
                  //<PreEcho obj ={row.original} />
                  //tabs => BomList / Development Status / Delivery Status
                  row.isExpanded  && (
                    <tr>
                        <td colSpan = {visibleColumns.length} className='p-3'>{<BomList row ={row.original} />}</td>
                    </tr>
                  )
                  
                  }
              </React.Fragment>
              )
            })}
          </tbody>
      </BTable>
      <Row className='ml-1 text-danger pagination'>
          <button tabIndex="-1" onClick={() => gotoPage(0)} disabled={!canPreviousPage} className='btn btn-sm btn-default'>
            {'<<'}
          </button>{' '}
          <button  onClick={() => previousPage()} disabled={!canPreviousPage} className='btn btn-sm btn-default'>
            {'<'}
          </button>{' '}
          <button  onClick={() => nextPage()} disabled={!canNextPage} className='btn  btn-sm btn-default'>
            {'>'}
          </button>{' '}
          <button tabIndex="-1" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage} className='btn  btn-sm btn-default'>
            {'>>'}
          </button>{' '}
          <span className='btn btn-sm btn-default'>
            {language==='en'?'Page':'页'}
            {' '}
            <strong>
              {pageIndex + 1} / {pageOptions.length}
            </strong>{' '}
          </span>
      </Row>
      </>
    )
};

//the table
const ForecastMain = ({handleShow})=>{

    const {data,toList} = useSelector(state=>state.forecast);
    const {userInfo}    = useSelector(state=>state.userLogin);

    React.useEffect(()=>{
        if(!data?.length) return;
        try {
          publish({
              type:'FORECAST_FETCH',
              payload:userInfo
          });

        }  catch (error){}

    },[data.length,toList,userInfo]);

    const [skipPageReset, setSkipPageReset] = React.useState(false);
    const [autoResetGlobalFilter, setautoResetGlobalFilter] = React.useState(false);
    const [update,setUpdate] = React.useState(false); 

    const hides = Object.keys(data[0] || {}).filter(
        key=>![
          'item_code',
          'ref_no',
          'price',
          'qty'

      ].includes(key)
    );

    const columns =   Object.keys(data[0] || {}).filter(k=>!hides.includes(k)).map((k)=>{
      return k === 'qty' ? {
          Header:<Translate k={k}/>,
          accessor: k,
          Cell: EditableCell,
          Filter:SelectFilter,
          filter: 'equals'
      } : {
          Header:<Translate k={k}/>,
          accessor: k
      }
   });

    // const columns = React.useMemo(
    //     () => {
    //       // console.log(products[0]);
    //       return Object.keys(forecast[0]||{}).filter(k=>!hides.includes(k)).map((k)=>{
    //             return k === 'qty' ? {
    //                 Header:<Translate k={k}/>,
    //                 accessor: k,
    //                 Cell: EditableCell,
    //                 Filter:SelectFilter,
    //                 filter: 'equals'
    //             } : {
    //                 Header:<Translate k={k}/>,
    //                 accessor: k
    //             }
    //       })},
    // [forecast,hides]);

        //change the data when it is necessary

      
    const [dt,setData] = React.useState(data);
    
    const [modified,setModified] = React.useState(false);
    
    const updateMyData = (rowIndex, columnId, value) => {
          // We also turn on the flag to not reset the page
          setSkipPageReset(true)
          setautoResetGlobalFilter(true);
          setData(old =>
            old.map((row, index) => {
              if (index === rowIndex) {
                return {
                  ...old[rowIndex],
                  [columnId]: value,
                }
              }
              return row
            })
          )
          setModified(true);

          setUpdate(true);
    }
      
    // React.useEffect(() => {
    //     setSkipPageReset(false);
    //     setautoResetGlobalFilter(false);
    // }, [update]);
    
   

    return (
       <>
          <div className ='eparts-row'>
                    <span style={{position:'relative'}}>
                        <span 
                           className={`btn btn-sm btn-outline-danger rounded`}
                           onClick = {()=>handleShow()}
                        >
                          <i className='fa fa-list'></i>
                        </span>
                      
                        <Badge 
                            className='rounded-circle text-danger border-danger p-1' 
                            bg = 'light'
                            style = {{position:'absolute',top:'-10px',right:'-10px',fontSize:'85%',backgroundColor:'#e2e2e2'}}   
                        >
                          {toList?.length}
                        </Badge>
                    </span>
          </div>
          {
          data?.length > 0 ? ( <Table 
            columns={columns} 
            data={dt?.length > 0 && modified ?dt:data} 
            hides = {hides}
            updateMyData ={updateMyData}
            skipPageReset={skipPageReset}
            autoResetGlobalFilter = {autoResetGlobalFilter}

          /> ) :(<Loader />)
        }
       </>
      
      
    );

};

export default ForecastMain;