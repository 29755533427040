
import React from 'react';
// import {useSelector} from 'react-redux';

import Loader from '../Loader';
import ShowPhoto from '../../modals/ShowPhoto';
const ChatInput = ({submit,keyUp,disable,errMsg,isProxy})=>{
   const [value,setValue] = React.useState('');

   const [loading,setLoading] = React.useState(false);
   const [showPhoto,setShowPhoto] = React.useState(false);

   const handle = ()=>{
      setShowPhoto(!showPhoto);
   };
 //    const {userInfo} = useSelector(state=>state.userLogin);
   //if there is only proxy Message, no talk is possible...
//    const isProxy = getMessage()[0]?.channel === 'proxy' ? true :false;

   const clickHandler = ()=>{
       if(!value) return alert('没有输入信息...');
       //effect the submit
       setLoading(true);
       submit(value);
       setValue('');
       setTimeout(()=>{
          setLoading(false);
       },3000);
   }
   //keyDown handler
   const keyUpHandler = (e)=>{

         if(e.key === 'Enter'){
            clickHandler();
         } else {
            keyUp(e);
         }
   }

   
   return (
    <div className='mb-3'>
        <div className="d-flex flex-row mt-3">
            {/* <button type="button" className="btn btn-outline-primary rounded-circle border-0 w-40px h-40px">
                <i className="fa fa-plus-circle" />
            </button> */}
            {/* <button type="button" 
                 className="btn btn-outline-primary rounded-circle border-0 w-40px h-40px ml-2"
                 onClick = {handle}
            >
                <i className="fa fa-image" />
            </button>
            {showPhoto && (<ShowPhoto rowData ={{test:'...'}} handle = {handle}/>)} */}
            
            {/* <button type="button" className="btn btn-outline-primary rounded-circle border-0 w-40px h-40px">
                <i className="fa fa-sticky-note" />
            </button>
            <button type="button" className="btn btn-outline-primary rounded-circle border-0 w-40px h-40px">
                <i className="fa fa-file" />
            </button> */}
        </div>
        {!isProxy ? ( <div className="input-group mt-2" style = {{padding:'3px 25px'}}>
            <input
                type="text"
                className="form-control shadow-none eparts-input"
                style ={{minWidth:'200px',border:'0.75px solid #C4261D',padding:'8px 12px',backgroundColor:'#fff',transition:'none !important'}}
                placeholder="..."
                value={value}
                onChange={(e) => setValue(e.target.value)}
                onKeyUp={keyUpHandler}
            />

            <div className="input-group-append">
                {
                //start by disable false...
                !loading ? ( <button type="button" disabled={disable} className={`btn btn-sm btn-outline-danger rounded`} onClick={clickHandler}>
                    <i className="fa fa-paper-plane" />
                </button>):(<button type="button" disabled={true} className="btn btn-sm btn-outline-danger rounded"><Loader size = '15px'/></button>)
                }
            </div>
        </div>) : (<p className ='mt-3 text-danger'>代理模式,不能聊天对话...</p>)}
    
        {errMsg && (<span className='mt-2 text-danger'>{errMsg}</span>)}
    </div>

   );
};

export default ChatInput;