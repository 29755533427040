
import React from 'react';
import { useTable,useRowState,usePagination} from 'react-table';
import useTableState from '../../hooks/useTableState';
import { publish } from '../../utils/socket';
import LanguageContext from '../../contexts/contexts';
import BTable from 'react-bootstrap/table';
import Translate from '../../components/Translate';
import { Row } from 'react-bootstrap';
import CountDownTimer from '../../components/timer/CountDownTimer';
// import UpdateColumnModal from '../../modals/UpdateColumnModal';
import PlaceOrder from './PlaceOrder';
import UpdateCell from './UpdateCell';
import { useSelector } from 'react-redux';
import Loader from '../../components/Loader';

function Table({ columns, data}) {

   let [loading,setLoading] = React.useState(false);

   const {PageOpenID} = useSelector(state=>state.userLogin);
   const {language} = React.useContext(LanguageContext);
   const tempPage = sessionStorage.getItem('tempPage')?sessionStorage.getItem('tempPage'):0;
   // Use the state and functions returned from useTable to build your UI
   const tableInstance = useTable(
      {
         columns,
         data,
         initialState:{
             pageSize:5
             ,pageIndex:parseInt(tempPage),
             tableName:'eparts_app.tb_to_buy'
         }
      },
      hooks=>{
         hooks.visibleColumns.push(columns=>[
            ...columns,
            {
             id:'action',
             Header: () => (<span className ='text-danger'>
                   <Translate k = {'action'} />
             </span>),
             // accessor:k,
             // ...getWidth(k),
             maxWidth: 5,
             minWidth: 3,
             width: 3,
             Cell: ({ cell ,state}) => {
                
                 const {flag,to,from,manager} = cell.row.original; 
                 let cls = 'secondary';

                 if(flag === 'O') 
                 {
                    cls = 'danger';
                 }

                 return (
                  <>
                   {
                   
                   [from,to,manager].includes(PageOpenID) 
                   && (
                        <span 
                        className={`btn btn-sm btn-outline-${cls} rounded`}
                        onClick = {()=>{
                           // setLoading(true);
                           // alert('clicked');
                           sessionStorage.setItem('tempPage',state.pageIndex);
                           let action = {
                              type:'TASK_UPDATE',
                              payload:{
                              ...cell.row.original,
                              flag: flag === 'T'?'O':'T'
                              }
                           };
                           //  dispatch(action);
                           publish(action);
                           // setTimeout(()=>{
                           //    setLoading(false);
                           // },2000);
                        }}
                        >
                        {<i className ='fa fa-check'></i>}
                        </span>
                   )} 
                   { 
                   [from,manager].includes(PageOpenID) &&
                    (<span className={`btn btn-sm btn-outline-${cls} rounded ml-2`}>
                        {flag}
                       </span> )
                    
                    }
                
                  
                  </>
                 
                 );
             }
            }
         ]);
      },
      useRowState,
      usePagination,
      useTableState  
      
   );

   const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      page,
      prepareRow,
      state: {pageIndex},
      //pagination
      canPreviousPage,
      canNextPage,
      pageOptions,
      pageCount,
      gotoPage,
      nextPage,
      previousPage
   } = tableInstance;
  
   return (
      <>
         <div className='eparts-row bg-white p-3 shadow rounded'>
            <BTable bordered hover size="sm"  className='mt-3' { ...getTableProps()} >
               <thead>
                  {headerGroups.map(headerGroup => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                     {headerGroup.headers.map(column => (
                        <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                     ))}
                  </tr>
                  ))}
               </thead>
               <tbody {...getTableBodyProps()}>
                  {
                     page.map((row,i) => {
                        prepareRow(row);
                        return (
                           <React.Fragment key = {i}>
                              <tr {...row.getRowProps()}>
                                    {
                                    row.cells.map((cell,i) => {
                                       return (
                                          <>
                                             <td {...cell.getCellProps()}  className='eparts-td'>
                                                  { 
                                                  cell?.state?.UpdateCell === true
                                                  ?(<UpdateCell  cell = {cell} pageIndex = {pageIndex} />) 
                                                  :cell.render('Cell')
                        
                                                  }
                                             </td>
                                          </>
                                          );
                                       })
                                    }
                                 </tr>
                           </React.Fragment>
                        );
                     })
                  }
               </tbody>
            </BTable>
            <Row className='ml-1 text-danger pagination eparts-row'>
               <button tabIndex="-1" onClick={() => gotoPage(0)} disabled={!canPreviousPage} className='btn btn-sm btn-default'>
               {'<<'}
               </button>{' '}
               <button  onClick={() => previousPage()} disabled={!canPreviousPage} className='btn btn-sm btn-default'>
               {'<'}
               </button>{' '}
               <button  onClick={() => nextPage()} disabled={!canNextPage} className='btn  btn-sm btn-default'>
               {'>'}
               </button>{' '}
               <button tabIndex="-1" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage} className='btn  btn-sm btn-default'>
               {'>>'}
               </button>{' '}
               <span className='btn btn-sm btn-default'>
               {language==='en'?'Page':'页'}
               {' '}
               <strong>
                  {pageIndex + 1} / {pageOptions.length}
               </strong>{' '}
               </span>
            
             
            </Row>
         </div>
      </>
   )
 };
const ToBuy = ({data}) =>{

   const [showTable,setShowTable] = React.useState(false);

   const [showMessage,setShowMessage] = React.useState(false);

   React.useEffect(()=>{
      if(!data?.length) return;
   },[data?.length]);
    
   const hides = Object.keys(data[0] || {}).filter(
      key=>![
      'part_no',
      // 'autoline',
      'ref',
      'qty_to_buy',
      'price_old',
    ].includes(key)
   );

   const columns = Object.keys(data[0]||{}).filter(k=>!hides.includes(k)).map((k)=>{
      return {
          Header:<Translate k={k}/>,
          accessor: k,
          Cell : (cell)=>{
                switch(cell.column.id)
                {
                  case 'qty_to_buy':
                     return (
                               <span>
                                 {cell.row.original.qty_change_to > 0 
                                      ?cell.row.original.qty_change_to
                                      :cell.value}
                                </span>
                            );
                   
                  case 'price_old':
                     let price = cell.row.original.price_new > 0 ? cell.row.original.price_new :cell.value;
                     return (
                        <span>
                           {parseFloat(price).toFixed(2)}
                        </span>
                     );
                  default:
                    return <span>{cell.value}</span>
                }
          }
      }
   });

   let dt = React.useMemo(()=>data,[data]);

   return (
       <>
         <div className='eparts-row bg-light p-1 shadow my-2 rounded'>
             <div className ='col-md-6 col-xs-12'>
              <div className='form-group mt-3' style = {{maxWidth:'300px'}}>
                 {/* <label className='text-danger ml-3' style = {{fontWeight:550}}>截止接单时间:</label> */}
                 { data[0]?.expire_time && (<CountDownTimer targetDate = {data[0].expire_time }/>)}
              </div>
            </div>
            <div className='col-md-6 col-xs-12'>
               <PlaceOrder data = {data} setShowTable={setShowTable} showMessage = {showMessage} setMessage = {setShowMessage} />
            </div>
         </div>   
          {showMessage && (<h4>message box</h4>) }
          {showTable && (<Table columns = {columns} data = {dt} />)}
          {/* <PreEcho obj = {data} /> */}
       </>
   );

};

export default ToBuy;