import React from 'react';
import { publish } from '../../utils/socket';
import Loader from '../../components/Loader';

const UpdateCell =({cell,pageIndex})=>{
    
    let {action:{type,column}} = cell.state;
    
    const [loading,setLoading] = React.useState(false);

    const [payload,setPayload] = React.useState(cell.row.original);

    const onChange = (e)=>{ 

        //  if(!parseFloat(e.target.value)) return;

          setPayload({
              ...payload,
              [column]:e.target.value
          });
    }

    const onBlur = async (e)=>{
          
        try {
            //go back
            if(!parseFloat(payload[column])) {
                
                return cell.setState(state=>{
                    return {
                        ...state,
                        UpdateCell:!state?.UpdateCell
                    }
                })
            }
            // return;

            sessionStorage.setItem('tempPage',pageIndex);

            setLoading(true);

            setTimeout(async()=>{
                
                setLoading(false);

                cell.setState(state=>{
                    return {
                        ...state,
                        UpdateCell:!state?.UpdateCell
                    }
                })

                await publish({type,payload});
            },3000);
        
        } catch(error){
            console.log(error.messsage);
        }

    }

    return (
        <div className ='input-group'>
            <input 
             type = 'number' 
             className ='form-control eparts-input'
             value    = {payload[column]}
             onChange = {onChange}
             onBlur   = {onBlur}
           />
           <div className ='input-group-append'>
              <span className ='btn btn-sm rounded'>
                   { loading
                     ?(<Loader size ='20px' />)
                     :cell.row.original[column==='qty_change_to'?'qty_to_buy':'price_old']
                   }
              </span>
           </div>
        </div>
       
    )

};

export default UpdateCell;