
import axios from 'axios';
import React,{ useState } from "react";
// import { useSelector } from "react-redux";
// import { publish } from "../utils/socket";
import {Modal} from "react-bootstrap";
import CloseBtn from "../components/CloseBtn";
import Loader from '../components/Loader';
// import PreEcho from '../components/PreEcho';


// import {isDev,debugLog} from '../utils';

// import Drawing from "../components/Drawing";
const ShowDrawing = ({rowData,handle})=>{
    //  console.log('rowData',rowData);
    //  const {data,err} = useSelector(state=>state.catalog);
     const [show,setShow] = useState(true);
     const [loading,setLoading] = useState(false);
     const [file,setFile] = useState('');

    //  let {dwg:filename} = rowData;

    //  if(!/pdf$/.test(filename)){
    //       filename = rowData.scode+rowData.scross+'.pdf';
    //  }
    //  console.log('resource found',drawingExist);
     const handleClose = ()=>{
         setShow(false);
         handle(); //close the parent status
     }
     
    //  React.useEffect(()=>{
    //      (async()=>{
    //         try {
    //             const res = await publish({type:'BOM_LIST',payload:{item_code:rowData.item_code}});
    //             return ()=>{}
    //         } catch(error){
    //             console.log(error.message);
    //         }
    //     })();
    //  },[]);
      React.useEffect(()=>{
          (async()=>{
              try {
                setLoading(true);
                setFile('');
                const config = {
                  headers : {
                  'Content-Type':'application/json'
                  }
                };

                const URL =process.env.NODE_ENV === 'production'
                ?'https://service.eparts.cn/api/update'
                :'http://localhost:4000/api/update'; 

                const {data:{msg}} = await axios.post(URL,{item_code:rowData.item_code,dwg:'Y'},config);
                
                if(/ERROR/.test(msg))
                {
                   throw new Error(msg);
                }
                // console.log(msg);
                setFile(msg);
                setLoading(false);

              } catch(error){
                console.log(error.message);

              }

          })();

      },[rowData.item_code]);
      
     return (
      <Modal show={show} onHide={handleClose} className='modal-template'>
        <Modal.Header>
          <h4>DRAWING - {rowData.ref_no}</h4>
          <CloseBtn onClick ={handleClose} />
        </Modal.Header>
        <Modal.Body className='dwg-body'>
           {loading && (<Loader />)}
           {/* {data && data.length >0 && (<PreEcho obj = {data} />)} */}
           {file && (<embed src ={`data:application/pdf;base64,${file}`} className='dwg-embed'/>)}
        </Modal.Body>
      </Modal>
     );
}
export default ShowDrawing;