import { TASK_CANCEL, TASK_CONFIRM, TASK_ERROR, TASK_FETCH, TASK_REMOVE, TASK_UPDATE } from "../constants/taskConstants";

const taskReducer = (state = {task:[],errMsg:'',message:[]},action)=>{
     switch(action.type) {
         case TASK_FETCH:
            //none exist task in the redux
            const newTasks = action.payload.filter(x=>!state.task.find(t=>t.uuid === x.uuid));
            return {...state,task:[...state.task,...newTasks]}; //append the new ones
         case TASK_UPDATE:
            // console.log('action',action);
            let update = state.task.map(r=>r.uuid === action.payload.uuid ? action.payload : r);
            return {...state,task:[...update]};
         case TASK_CONFIRM:
            //{confirm_id,msg}
            // let newMessage = state.message.map(x=>x.confirm_id === action.payload.confirm_id ?action.payload:x);
            return {...state,message:[...state.message,action.payload]};
         case TASK_REMOVE:
            return {...state,task:state.task.filter(r=>r.uuid !== action.payload.uuid)};
         case TASK_CANCEL:
            //soft delete as flag 'D'
            return {...state,task:state.task.map(r=>r.uuid === action.payload.uuid ? {...r,flag:'D'}:r)};
         case TASK_ERROR:
            // let {errMsg} = action.payload;
            return {...state,errMsg:action.errMsg};
         
         default:
            return state;
     }

};

export default taskReducer;