
//@2024.10.25,show the photo and dimension
const Product = ({refNumber})=>{
    return (
    
        <pre>{refNumber}</pre>

    );
};

export default Product;