// import React from 'react';
// import {useSelector} from 'react-redux';
import pallet from '../images/pallet.jpg';
// import PreEcho from './PreEcho';
const Pallet = ()=>{
      
   //   const {PageOpenID,userInfo} = useSelector(state=>state.userLogin);
   //   const {messages} = useSelector(state=>state.chat);
   
   //   const  chatMessages = messages.filter(x=>[x.from,x.to,x.manager].includes(PageOpenID));

   //   let obj = {
   //      uuid   : 'xxxxxxx', //message
   //      from   :'oV9Cut8y8vzP3DzlLZWIJ8RNfdn8',
   //      to     :'oV9Cut_p46507NF2UwqJXhNq2P2w',
   //      manager:'oV9Cut8y8vzP3DzlLZWIJ8RNfdn8',
   //      item_code:'xxxx', 
   //      message:[{
   //          channel:'eparts',
   //          msgType:'text',//image
   //          msgContent:'xxx',
   //          owner:'from', //or to
   //          time:'....'
   //      }],
   //      product:{},  //show the source or catalog
   //      status:'T',
   //      expire_time:'2024-06-14 15:10:12'
   //   };



     return (
         <>
            
            <div className ='eparts-row shadow'>
               <p className ='mx-auto my-4 text-danger'>
                   未发现配合需求 | NO TODOS FOUND
               </p>
            </div>
            <div className ='eparts-row shadow mt-4'>
               <p className ='mx-auto mt-6'>
                  <img src = {pallet} width ='280' />
               </p>  
            </div>
      
         </>
        
      )
      
}

export default Pallet;