export const isWechat = ()=>{
    return /MicroMessenger/i.test(navigator.userAgent.toLowerCase()); //true or not
};

export const isMobile = ()=>{
    const  u = navigator.userAgent;
    return u.indexOf('Android') > -1 || u.indexOf('iPhone') > -1
};

//isDev
export const isDev = ()=>{
    return !process.env.NODE_ENV || process.env.NODE_ENV === 'development' ? true:false;
};

export const dateAdd = () =>{


}

//@2024.11.16
export const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
}