import { MESSAGE_SUCCESS, MESSAGE_TOGGLE, MESSAGE_UPDATE } from "../constants/messageConstants";

export const messageReducer = (state = {data:[],activeUser:''},action) => {
    switch (action.type){
        case MESSAGE_SUCCESS:
         //   console.log('check the messages',action.payload);
           return {...state,data:action.payload};
        case MESSAGE_UPDATE:
          //  console.log('new message',action.payload);
           return {...state,data:[...state.data.filter(x=>x.uuid !== action.payload.uuid),action.payload]};
        case MESSAGE_TOGGLE:
          return {...state,activeUser:action.payload.openID === state.activeUser ? '':action.payload.openID};
        default:
           return state;
    }
}