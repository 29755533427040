import { FORECAST_ERROR, 
   FORECAST_FETCH, FORECAST_LIST_UPDATE, FORECAST_TO_DELIVER, 
   FORECAST_TO_LIST, FORECAST_TO_OFFER, FORECAST_TO_SELL, FORECAST_UPDATE } from "../constants/forecastConstants";
export const forecastReducer = (state={data:[],toOffer:[],toSell:[],errMsg:[],toList:[]},action)=>{
       switch(action.type){
           case  FORECAST_FETCH:
              return {...state,data:action.payload};
           case FORECAST_TO_LIST:
              return {
                   ...state,
                   toList:action.payload   
              };

           case FORECAST_LIST_UPDATE:
              return {
                 ...state,
                 toList:state.toList.map(x=>{
                     return x._id === action.payload._id  ? action.payload : x
                 })
              }
            
           case  FORECAST_UPDATE:
              return {};

           default:
            return state;
       }
       
};
