import { PRODUCT_SEARCH } from "../constants/productConstants";



const productReducer = (state = {products:[]},action)=>{

       switch(action.type)
       {
           
           //@2024.10.26 => grab the product from database
           case PRODUCT_SEARCH:
               return { 
                    ...state, 
                    products:[...state.products.filter(x=>x._id !== action.payload._id),action.payload]
               };
           default:
             return state;
       }


};

export default productReducer;