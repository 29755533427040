import { UPLOAD_START, UPLOAD_SUCCESS } from "../constants/uploadConstants";

//upload => {uuid,contentType,base64}
const uploadReducer = (state={uploads:[],loading:false,errMsg:''},action)=>{
       
       switch(action.type)
       {
            case UPLOAD_START:
                return {
                    ...state,
                    loading:true
                }
            case UPLOAD_SUCCESS:
                return {
                    ...state,
                    loading:false,
                    uploads:[
                         ...state.uploads.filter(x=>x.uuid !== action.payload.uuid),
                         action.payload
                    ]
                }
            default:
              return state;
       }

};

export default uploadReducer;