// import {useSearchParams} from 'react-router-dom';
// import { io } from "socket.io-client";
import {socket} from '../../utils/socket';
import {subscribe} from './subscribe.js';
//it is good place to init the socket open
const socketMiddleware = (store)=>{
   //attach this sockt...
   subscribe(store,socket); //attach events
   //attach the socketIO
   return next=>(action)=>{
      //  publish(store,socket,action);

      //  if(action.type === 'TICKET_UPDATE'){
      //     socket.emit('message',JSON.stringify(action));
      //  }
       next(action);
   }
}
export default socketMiddleware;