

export const AUTOLINE_FETCH  = 'AUTOLINE_FETCH';
export const AUTOLINE_UPDATE = 'AUTOLINE_UPDATE';
export const AUTOLINE_APPEND = 'AUTOLINE_APPEND';

export const AUTOLINE_SOURCE_FETCH = 'AUTOLINE_SOURCE_FETCH';
export const AUTOLINE_ITEM_RESEARCH = 'AUTOLINE_ITEM_RESEARCH';
export const AUTOLINE_TO_SOURCE   = 'AUTOLINE_TO_SOURCE'; //list the source to redis store
// export const AUTOLINE_TO_LIST       = 'AUTOLINE_TO_LIST'; //list the source to redis store
