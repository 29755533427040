import axios from 'axios';
import React from 'react';
import {useSelector} from 'react-redux';
// import { useAsyncDebounce } from "react-table";

import { Modal } from 'react-bootstrap';
import Loader from '../../components/Loader';
import CloseBtn from '../../components/CloseBtn';
import { convertToBase64 } from '../../utils';
import ShowPhoto from '../../components/ShowPhoto';

const ClaimLabel = ({row,handle})=>{
    
    const [loading,setLoading] = React.useState(false);
    const [show,setShow] = React.useState(true);
    const [file,setFile] = React.useState('');



    const handleClose = ()=>{
        setShow(false);
        handle();
    }

    React.useEffect(()=>{
       
        (async()=>{

            try {
                
                setLoading(true);
                //http_request
                const config = {
                    headers : {
                       'Content-Type':'application/json'
                       // Authorization: `Bearer ${ userInfo.token }`
                    }
                };
                
                //EDIT => update the record
                const URL =process.env.NODE_ENV === 'production'
                ?'https://service.eparts.cn/api/claim_label'
                :'http://localhost:4000/api/claim_label'; 
              
                //claim_no,action
                const {data}  = await axios.post(URL,{...row},config);
                // console.log('response',data);
                setLoading(false);

                if(/ERROR/.test(data.errMsg)){
                    throw new Error(data.msg);
                }
                
                setFile(data.label);
        
            } catch(error){
                // setErr(error.message);     
            }
           

        })();

    },[row]);
   
    return (
        <Modal show={show} onHide={handleClose} className='modal-template'>
          <Modal.Header>
            <h4>label - {row.claim_no}</h4>
            <CloseBtn onClick ={handleClose} />
          </Modal.Header>
          <Modal.Body className='dwg-body'>
             {loading && (<Loader />)}
             {/* <pre>{JSON.stringify(source,null,2)}</pre> */}
             {/* {data && data.length >0 && (<PreEcho obj = {data} />)} */}
             {file && (<embed src ={`${file}`} className='dwg-embed'/>)}
          </Modal.Body>
        </Modal>
    );


};

const Claim = ({row,handlePublish}) =>{

      const hiddenInput = React.useRef(null);

      const [show,setShow] = React.useState(false);

      const {userInfo} = useSelector(state=>state.userLogin);
      
      const [business,setBusiness] = React.useState(row.data[0].row);

      const [value,setValue]       = React.useState('');

      const [showButton,setShowButton] = React.useState(false);
      const [showFileUpload,setShowFileUpLoad] = React.useState(false);

      const [file,setFile]             = React.useState('');
      const [loading,setLoading]       = React.useState('');

    //   React.useEffect(()=>{},[row.data[0].row]);

      const handle = ()=>{
          setShow(!show);
      }
 
     
      const onChange = (e)=>{

           

            setValue(e.target.value);

      }

      const onBlur = (e)=>{

        if(!e.target.value) return;


        let propertyName = '';

        switch(userInfo?.open_id)
        {    case 'oV9Cut506UDd1fO_aij7-1HB1yYU':
                propertyName = '备注';
                break;
            case 'oV9Cut89hpoPHRQBzssCDhpqeCLo':
                propertyName = 'pallet_no';
                break;
            default:
                
                propertyName = row.status === 'T'?'操作':'备注';

                if(row.status === 'P')
                {
                    propertyName = 'pallet_no';
                }

                // propertyName = 'pallet_no';
                // propertyName = '备注';
        }

        // if(!e.target.value) return;

        setBusiness({
            ...business,
            [propertyName]:propertyName === 'pallet_no' ? e.target.value.trim():business[propertyName]+';'+value
        });


        setValue('');

        setShowButton(true);
        setShowFileUpLoad(true);

        setTimeout(()=>{
            setShowButton(false);
        },10000);
           
      }

      const saveHandler = async (e)=>{

          try {

           if(!window.confirm('确认保存?')) return;

           await handlePublish({
                _id:row._id,
                type:'TODO_UPDATE',
                from:userInfo?.open_id,
                payload : {
                    type:'CLAIM',
                    status:'O',
                    payload:{
                        ...business
                    } 
                }
            });

          } catch (error){
             console.log(error.message);
          }
           
      }
    
      //upload the file
      const uploadHandler = async (e)=>{

            // setFile(URL.createObjectURL(e.target.files[0]));
        try {

            const file = e.target.files[0];

            if(!file)
            {
                throw new Error('NO FILE FOUND');
            }

            setFile(e.target.files[0]);

              // Check file size (in bytes)
            const maxSizeInBytes = 1 * 1024 * 1024; // 2MB

            // console.log('the file',file);
            if(row.status !== 'P')
            {
                return alert('不能重复上传文件');
            }

            if(file.name.split('.jpg')[0] !== business.claim_no)
            {
                 return alert('文件名称不符合要求...');
            }

            if(file.size > maxSizeInBytes)
            {
                // return msgBox('图片尺寸过大');
            }

            setLoading(true);

            const base64String = await convertToBase64(file);

            setFile(base64String);

            handlePublish({
                _id:row._id,
                type:'TODO_UPDATE',
                from:userInfo?.open_id,
                payload : {
                    type:'CLAIM',
                    status:'C',
                    payload:{
                        ...business,
                        file:base64String
                    } 
                }
            });

            setTimeout(function(){
                setLoading(false);
            },3000);

        } catch (error){

            console.log(error.message);
        }

      }
            

      return (
        <> 
            {show && (
                <ClaimLabel 
                    row = {{
                        claim_no:business.claim_no,
                        action:business.resolve_action,
                        from:userInfo.open_id
                    }} 
                    handle = {handle} 
                />
            )}

            <div className = 'eparts-row text-center shadow'>
                  <div className ='col-md-8 col-xs-12 p-4 text-center'>
                         <div className='form-group mx-auto ' style ={{maxWidth:'480px'}}>
                              <div className='row p-4 text-danger border border-primary rounded my-2'>
                                 { 'claim_no:'+business.claim_no +' / action: ' +business.resolve_action}
                              </div>
                              <div className ='row my-2'>
                                   <div className ='col-md-6 p-4 border border-primary rounded'>
                                      {
                                         /;/.test(business.操作) && (
                                             <>
                                                <p className='text-danger'>材料清单 | BOM LIST</p>
                                                <ul>
                                                    {business.操作.split(';').filter(x=>x.length>0).map((x,i)=>{
                                                        return (
                                                            <li key = {i}>{x}</li>
                                                        )
                                                    })}
                                                </ul>
                                             </>
                                         )
                                      }
                                     
                                       
                                   </div>
                                   <div className ='col-md-6 p-4 border border-primary rounded'>
                                       {
                                                /;/.test(business.备注) && (
                                                    <>
                                                       <p className='text-danger'>盘亏 | ACTION</p>
                                                       <ul>
                                                           {business.备注.split(';').filter(x=>x.length>0).map((x,i)=>{
                                                               return (
                                                                   <li key = {i}>{x}</li>
                                                               )
                                                           })}
                                                       </ul>
                                                    </>
                                                )
                                    
                                       }
                                   </div>
                              </div>
                              <div className ='row'>
                                  <label className ='text-danger'>
                                    托盘号:
                                    <b>
                                        {
                                            business.pallet_no.replace(';','')
                                        }
                                    </b>
                                  </label>
                                  <div className ='input-group'>
                                      <div className='input-group-prepend'>

                                        <span className = 'btn btn-sm btn-outline-danger rounded'
                                            onClick = {handle}
                                        >
                                           <i className ='fa fa-file-pdf-o'></i>
                                        </span>
                                      </div>
                              
                                      <input 
                                        type ='text' 
                                        className ='form-control eparts-input' 
                                        placeholder ='...'
                                        value = {value}
                                        onChange = {onChange}
                                        onBlur   = {onBlur}
                                        
                                      />
                                      <div className ='input-group-append'>
                                          {/* <span className ='btn btn-sm btn-outline-danger rounded'>
                                              <i className = 'fa fa-save'></i>
                                          </span>
                                          <span className ='btn btn-sm btn-outline-danger rounded'>
                                              <i className = 'fa fa-sticky-note-o'></i>
                                          </span>

                                          <span className ='btn btn-sm btn-outline-danger rounded'>
                                              <i className = 'fa fa-upload'></i>
                                          </span> */
                                          }

                                          {
                                            ( business?.操作 ||  business?.备注 ||  business.pallet_no !== '#') && showButton && (
                                                <span 
                                                   className ='btn btn-sm btn-outline-danger rounded'
                                                   onClick = {saveHandler}
                                                >
                                                  <i className = 'fa fa-save'></i>
                                                </span>
                                             )
     
                                          }
                                      
                                      </div>
                                  </div>
                              </div>
                              
                         </div>
                  </div>
                  <div className ='col-md-4 col-xs-12'>
                         <div className ='form-group' style = {{maxWidth:'480px'}}>
                               <div className ='row text-right p-1'>   
                                    {
                                        /*--- with status P, it means pallet_no is ready ---- */
                                        business?.pallet_no && business.pallet_no !== '#' && business.status === 'P' && showFileUpload && (
                                        <>
                                          <input 
                                            type = 'file' 
                                            accept='image/*' 
                                            className ='d-none'
                                            onChange = {uploadHandler}
                                            // style = {{maxWidth:'280px'}}
                                            ref = {hiddenInput}
                                            
                                          />
                            
                                        <span 
                                            className ={`btn btn-sm btn-outline-danger ${row.status !== 'P' ?'btn-outline-secondary':''} rounded mt-4`}
                                            onClick = {()=>{
                                                    if(row.status !== 'P') return;
                                                    hiddenInput.current.click()
                                            }}
                                        >
                                            <i className = 'fa fa-upload'></i>
                                        </span>
                                        </>
                                       
                                    )
                                    }
                               </div>
                               <div className ='row text-center p-2'>
                                    {loading && (<Loader />)}
                                    <ShowPhoto row = {business} />             
                               </div>
                         </div>
                  </div>
            </div>
            {/* <pre>{JSON.stringify(row,null,2)}</pre> */}
        </>
  
      );
};

export default Claim;