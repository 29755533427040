//useSelector,  
//store.dispatch the action

// import { USER_LOGIN_SOCKET, USER_LOGIN_SUCCESS, USER_LOGIN_SWITCH } from "../constants/userConstants";
import * as userConstants from "../constants/userConstants";
import * as forecastConstants from '../constants/forecastConstants';
import * as catalogConstants from '../constants/catalogConstants';
import * as messageConstants from '../constants/messageConstants';
import * as researchConstants from '../constants/researchConstants';
import * as taskConstants     from '../constants/taskConstants';
import * as paymentConstants  from '../constants/paymentConstants';
import * as sourceConstants   from '../constants/sourceConstants';
import * as itemConstants  from '../constants/itemConstants';
import * as tobuyConstants from '../constants/toBuyConstants';
import * as todoConstants  from '../constants/todoConstants';
import * as autolineConstants from '../constants/autolineConstants';
import * as inputConstants  from '../constants/inputConstants';
import * as wepayConstants  from '../constants/wepayConstants';
import * as uploadConstants from '../constants/uploadConstants';
// import * as chatConstants  from '../constants/chatConstants';

// console.log('userConstants',Object.values(userConstants));
// console.log('forecastConstants',Object.values(forecastConstants));
//manage the login action ,ticket_update, etc...
export const subscribe = (store,socket)=>{

      try {
        //attach all the constants
        const CONSTANTS = [
          ...Object.values(userConstants),
          ...Object.values(forecastConstants),
          ...Object.values(catalogConstants),
          ...Object.values(messageConstants),
          ...Object.values(researchConstants),
          ...Object.values(taskConstants),
          ...Object.values(paymentConstants),
          ...Object.values(sourceConstants),
          ...Object.values(itemConstants),
          ...Object.values(tobuyConstants),
          ...Object.values(todoConstants),
          ...Object.values(autolineConstants),
          ...Object.values(inputConstants),
          ...Object.values(wepayConstants),
          ...Object.values(uploadConstants),
          // ...Object.values(chatConstants),
          'TEST'
        ];

        //@20240228 ,attach all user events...
        CONSTANTS.forEach((type)=>{
              //  console.log(type);
              socket.on(type,(action)=>{
                  if(action?.type !== type) return;

                  store.dispatch(action);

                  if(['TODO_FETCH','USER_LOGOUT'].includes(action?.type))
                  {
                      //remove the loding status
                      store.dispatch({
                           type:'USER_LOGIN_PAGEID',
                           payload:{
                               loginLoading:false
                           }
                      })
                  }
              });
        });

      } catch(error){
         console.log('onSocket error',error.message);
      }
};

