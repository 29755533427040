import { set } from 'mongoose';
import React from 'react';

const BrandRef = ({row,handlePublish})=>{
    // console.log(row);

    const {_id} = row;
    
    const [business,setBusiness]  = React.useState(row.data[0].row); //


    const changeHandler = (e)=>{
         
          setBusiness({
              ...business,
              [e.target.name]:e.target.value
          })
    }

    const submitHandler = (e)=>{
          if(window.confirm('are you sure to submit:'+business.brand_ref+' ?'))
          {
            handlePublish({
                _id,
                type:'TODO_UPDATE',
                payload : {
                    type:'brandRef',
                    status:'O',
                    payload:{
                        ...business
                    }
                }
            });
          }
    }

    const cancelHandler = (e)=>{
        if(window.confirm('are you sure to cancel:'+business.market_brand_ref+' ?'))
        {
            handlePublish({
                _id,
                type:'TODO_UPDATE',
                payload : {
                    type:'brandRef',
                    status:'C',
                    payload:{
                        ...business
                    }
                }
            });
        }
    }

    return (
        <>
           <div className ='eparts-row'>
               <div className ='col-md-6'>
                  {
                    business.picture !== '#' && (
                            <img 
                            src = {business.picture} 
                            width = '320' 
                            alt = 'photo' 
                            className ='mx-auto p-3' 
                        />
                    )
                  }
                 
               </div>
               <div className ='col-md-6'>
                     
                     <div className ='form-group'>
                          <label>{business.market_brand_ref} / <b className='text-danger'>{business.brand_ref}</b></label>

                          <div className ='input-group'>

                            <div className ='input-group-prepend'>
                                <span 
                                    className ='btn btn-sm rounded btn-outline-danger'
                                    onClick = {cancelHandler}
                                >
                                     <i className ='fa fa-times'></i>
                                </span>
                            </div>
                            <input 
                                className ='form-control eparts-input'
                                name = 'brand_ref'
                                type ='text' 
                                placeholder = '...'
                                value    = {business.brand_ref}
                                onChange = {changeHandler}

                            />

                            {
                                business.brand_ref !== '#' && business.brand_ref.length > 0 && (
                                    <div className ='input-group-append'>
                                        <span 
                                        className='btn btn-sm btn-outline-danger rounded'
                                        onClick = {submitHandler}
                                        >
                                        <i className ='fa fa-save'></i>
                                        </span>
                                    </div>
                                )
                            }
                          
                          </div>
                         
                     </div>

               </div>
           </div>
        
        </>
    )
}

export default BrandRef;