import React  from 'react';
import { Badge } from "react-bootstrap";

//render the users
const SideBar = ({users,clickPID,messages,toggle})=>{
  
    const [hides,setHides] = React.useState([]);
    
    const hideHandler = (pid)=>{
         setHides([...hides,pid]);
    }

    return (
      <div className="d-flex flex-column h-100 p-2">
        {
          users.length  && (users.filter(x=>!hides.includes(x.pid)).map((user,i)=>{
              return  (
                  <div className="mt-2 rounded w-100" key = {i}>
                    <span style={{position:'relative'}} className ='mt-4'>
                        <span 
                           className={`btn btn-sm btn-outline-${user.active ?'danger':'secondary'} rounded`}
                           onClick = {()=>{
                            clickPID(user.pid);
                            toggle();
                          }}
                        >
                          <i className='fa fa-user mr-1'></i>{user.pid}
                          {/* {messages?.length > 0 && (<span className ='ml-1 text-dark'>{messages[0].channel}</span>)} */}
                        </span>
                      
                        <Badge 
                            className='rounded-circle text-danger border-danger p-1' 
                            bg = 'light'
                            style = {{position:'absolute',top:'-12px',right:'-12px',fontSize:'85%',backgroundColor:'#e2e2e2'}} 
                            onDoubleClick = {
                               ()=>hideHandler(user.pid)
                            }
                        >
                        {user.count}
                        </Badge>
                    </span>
                  </div>
            );
          }))
        }
      </div>
    );
}

export default SideBar;