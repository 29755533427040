import React from 'react';
import {useSelector} from 'react-redux';
import { publish } from '../utils/socket';
//prepare it for message
const useMessage = ()=>{
    
    // const dispatch = useDispatch();
    const {PageOpenID,userInfo} = useSelector(state=>state.userLogin);
    const {data}     = useSelector(state=>state.message);
    // const [num,setNum] = React.useState(0);
    
    //data.filter(m=>m.from !== userInfo.open_id)[0].PID
    let temp = data.filter(m=>m?.from !==userInfo?.open_id)[0]?.PID
    // console.log('temp',temp);
    const [initPID,setInitPID] = React.useState(temp);
    // let tempID = initPID ? initPID : temp;
    const [activePID,setActivePID] = React.useState(initPID); //get thefirst
    // console.log('init activePID',activePID)
    const [focusPID,setFocusPID]   = React.useState(0);//t beginning with ZERO;
    const [disable,]    = React.useState(false);
    const [error,setError]         = React.useState('');

    const isProxy = PageOpenID !== '#'  && PageOpenID !== userInfo?.open_id;

    const allPID =  [...new Set(data.filter(m=>m?.from !== PageOpenID).map(msg=>msg?.PID))].map(pid=>{
         return {
             pid,
             count :data.filter(m=>m?.PID === pid).length,
             active:activePID === pid
         }
    });
    
    // let n = setInterval(()=>setNum(num + 1),3000);

    
    // React.useEffect(()=>{
  
    //     if(num > 5)
    //     {
    //        clearInterval(n);
          
    //     }
    //     document.title = `[${num}] EPARTS|宜配驰`;
    // },[]);

    // console.log('allPID',allPID);
    //change the activePID
    const clickPID = (pid)=>{
          // console.log('the pid',pid);
          if(pid !== activePID){
            setActivePID(pid);
            setInitPID(pid);
          }
    };


    //ivoke on inputKeyDown
    const inputKeyUp = (e)=>{
        if(!e.target.value)   return;
        setFocusPID(activePID);
    };

    //the messages from activePID related...
    const getMessages = ()=>{
          // console.log('the activePID',activePID);
          // const pid = activePID;
          const  openID = data.find(m=>m.PID === activePID)?.from; //the the from
          //assign read property for each message 
          //if the focusPID === activePID
          const messages = data
                           .filter(m=>m?.PID !== undefined)
                           .filter(m=>[m?.from,m?.to].includes(openID))
                           .sort((a,b)=>b.timestamp - a.timestamp)
                           .map(m=>{ return {...m,read:focusPID === m.PID ? true:false}});

          //over only two messages allowed to send before reply... 
           // const  openID = data.find(m=>m.PID === activePID)?.from; //the the from
                 

          // console.log('fired',messages);
          return messages;
    };
    //compose the msgBody, and pubish to server
    const submitHandler = (value)=>{
         (async()=>{
            try {

             
                      
              if(!value) 
              {
                  throw new Error('NO MESSAGE');
              }

              //compose the msgObj
              const msgObj = {
                 type:'MESSAGE',
                 from:data.find(m=>m.PID === activePID).to,
                 to  :data.find(m=>m.PID === activePID).from,
                 MsgType:'text',
                 Content:value.trim()
              };
              
              await publish({type:'MESSAGE_UPDATE',payload:msgObj});
            } catch (error){

              setError(error.message);

              setTimeout(()=>{
                  setError('');
              },4000);
              // console.log('submit error',error.message);
            }
         })();
    };

    return {isProxy, error, disable,activePID,focusPID,allPID,clickPID,inputKeyUp,getMessages,submitHandler};

  
}

export default useMessage;