import axios from 'axios';
import React from 'react';
import {Modal} from "react-bootstrap";
import CloseBtn from "../../components/CloseBtn";
import Loader from '../../components/Loader';

const Location = ({part_no,_id}) =>{
      
      const [location,setLocation] = React.useState('');

      const [note,setNote]         = React.useState('');

      const changeHandler = (e)=>{
           setNote(e.target.value)
      }

      React.useEffect(()=>{
        (async()=>{
            try {

            //   setLoading(true);
            //   setFile('');

              const config = {
                headers : {
                'Content-Type':'application/json'
                }
              };

              const URL =process.env.NODE_ENV === 'production'
              ?'https://service.eparts.cn/api/getlocation'
              :'http://localhost:4000/api/getlocation'; 

              const {data:{result}} = await axios.post(
                URL,
                {
                 part_no
                },
                config
              );

              if(result)
              {
                setLocation(result);
              }
              
            //   setLoading(false);

            } catch(error){
              console.log(error.message);
              alert(error.message);

            }

        })();


      },[part_no]);

      /*
            
        {
        "序号": 27724,
        "demandID": 0,
        "仓库": "01-大仓库",
        "库位": "10CD0704",
        "物料": "60-004SR",
        "描述": "密封圈TCJY 40-52/58-7.5",
        "结存数": 9921,
        "批号": "21S0019",
        "盘点需求": "C",
        "盘点记录": "",
        "操作日志": "",
        "upd_date": "2024-08-02T01:24:46.000Z"
        }
      */

      return (
        <>
            { 
              location && (
                <div className ='form-group W-70'>
                    <label className ='text-dark' 
                        style = {{fontSize:'120%'}}>
                        <b className ='text-danger'>{location.物料}</b>|{location.仓库}|{location.库位}|{location.描述}| 库存量:{location.结存数}
                    </label>
                    {/* <label>{_id}</label> */}
                    <div className ='input-group'>
                         <input 
                              type = 'text' 
                              name ='note' 
                              className ='form-control eparts-input'
                              placeholder = '备注...'
                              value    = { note }
                              onChange = { changeHandler }
                         />
                         <div className ='input-group-append'>
                              <span 
                                  className ='btn btn-sm btn-outline-danger rounded'
                                  onClick   = {()=>alert('save the comments')}
                               >
                                { note?.length >0  ? (<i className ='fa fa-times'></i>) :(<i className = 'fa fa-check'></i>)}
                              </span>
                         </div>
                    </div>
               </div>
              ) 
            }
        </>

      );
}

const ShowDrawing  = ({row,handle,_id})=>{

     
    const [show,setShow]       = React.useState(true);
    const [loading,setLoading] = React.useState(false);
    const [file,setFile]       = React.useState('');
     
    const handleClose = ()=>{
        setShow(false);
        handle(); //close the parent status
    }
   
    React.useEffect(()=>{
        (async()=>{
            try {

              setLoading(true);
              setFile('');

              const config = {
                headers : {
                'Content-Type':'application/json'
                }
              };

              const URL =process.env.NODE_ENV === 'production'
              ?'https://service.eparts.cn/api/get_drawing'
              :'http://localhost:4000/api/get_drawing'; 

              const {data:{drawing}} = await axios.post(
                URL,
                {
                 part_no:row.row.物料编号,
                 from:row.to
                },
                config
              );

              if(drawing)
              {
                setFile(drawing);
              }
              
              setLoading(false);

            } catch(error){
              console.log(error.message);
              alert(error.message);

            }

        })();

    },[row]);



    return (
        <Modal show={show} onHide={handleClose} className='modal-template'>
        <Modal.Header>
          {/* <h4>图纸 - {row.row.物料编号}</h4> */}
           <Location part_no = {row.row.物料编号} _id = {_id} />
          <CloseBtn onClick ={handleClose} />
        </Modal.Header>
        <Modal.Body className='dwg-body'>
           {loading && (<Loader />)}
           {/* {data && data.length >0 && (<PreEcho obj = {data} />)} */}
           {file && (<embed src ={`data:application/pdf;base64,${file}`} className='dwg-embed'/>)}
           {/* <pre>{JSON.stringify(data,null,2)}</pre> */}
        </Modal.Body>
      </Modal>
    );
}
const DrawingInput = ({row,handlePublish,_id}) => {

    //   const [file,setFile] = React.useState('');
      const [loadingFile,setLoadingFile] = React.useState(false);
      const [loading,setLoading] = React.useState(false);
      const [business,setBusiness] = React.useState(row); 

      const [showDrawing,setShowDrawing] = React.useState(false);

      const handleShow = ()=>{
          setShowDrawing(!showDrawing);
      }

      const hiddenFileInput = React.useRef(null);
     
      const handleClick = (e)=>{
          hiddenFileInput.current.click();
      }

      const handleChange = async (e)=>{
         
           try {

            const fileUploaded = e.target.files[0];

            if(!fileUploaded) return;
             
            // setFile(fileUploaded);
             
            setBusiness({
                ...business,
                row : {
                    ...business.row,
                    [e.target.name]:e.target.files[0]
                }
            })

           } catch (error){
               console.log(error.messsage);
           }
      }

      const submitHandler = async(e)=>{

        try {
          setLoading(true);
          //upload the business row with binary data of pdf file
          handlePublish({
              _id,
              type:'TODO_UPDATE',
              payload : {
                  type:'DRAWING',
                  payload:{
                      ...business
                  }
              }
          });

          setTimeout(()=>{
              setLoading(false);
          },2000);

        } catch(error){
           console.log(error.message);
        }
      };

      React.useEffect(()=>{

        (async()=>{
            try {

            //   setLoading(true);

              setLoadingFile(true);

              const config = {
                headers : {
                'Content-Type':'application/json'
                }
              };

              const URL =process.env.NODE_ENV === 'production'
              ?'https://service.eparts.cn/api/get_drawing'
              :'http://localhost:4000/api/get_drawing'; 

              const {data:{drawing}} = await axios.post(
                URL,
                {
                 part_no:row.row.物料编号,
                 from:row.to
                },
                config
              );

              if(drawing)
              {
                
                setBusiness({
                    ...business,
                    row : {
                        ...business.row,
                        file:true
                        //     [e.target.name]:e.target.files[0]
                    }
                })
                // setFile(drawing);
              }
              
            //   setLoading(false);
               setLoadingFile(false);

            } catch(error){
              console.log(error.message);
              alert(error.message);

            }

        })();
      },[row]);
    
      return (
         <div className ='eparts-row'>
            <div className ='form-group mx-auto my-3' style = {{maxWidth:'320px'}}>
              <label>{row.row.物料编号} <span className ='ml-3 text-danger'>图纸正面朝上*</span></label>
              <div className ='input-group'>
                   <div className ='input-group-prepend'>
                      {/*---row.row.drawingExist----*/}
                      {
                         business.row?.file  ? (
                            <>
                                <span 
                                    className = {`btn btn-sm btn-outline-danger rounded`}
                                    onClick = {handleShow}
                                >
                                   <i className ='fa fa-file-pdf-o'></i>
                                </span>
                                {showDrawing && (<ShowDrawing row = {business} handle = {handleShow} _id = {_id}/>)}
                            </>
                        
                         ) : (
                            <span 
                                className ='btn btn-sm btn-outline-danger rounded'
                                onClick   = {handleClick}
                            >
                             {!loadingFile  ? (<i className ='fa fa-upload'></i>) :(<Loader size ='17' />)}
                            </span>
                        )

                      }
                      
                   </div>

                   <input 
                        type = 'file' 
                        name ='file' 
                        className ='d-none' 
                        onChange={ handleChange }
                        accept = 'application/pdf'
                        ref     ={hiddenFileInput}
                   />

                   <input 
                      type = 'number' 
                      className ='form-control eparts-input' 
                      value = {row.cost} 
                      onChange = {()=>{}}
                      readOnly = {true}
                   />

                   {
                      business?.row?.file && (
                        <div className ='input-group-append'>
                            <span 
                                className ='btn btn-sm btn-outline-danger rounded'
                                onClick   = {submitHandler}
                            >
                               { loading ? (<Loader size ='17' />) :(<i className ='fa fa-paper-plane'></i>) }
                            </span>
                        </div>
                      )
                   }
                 
              </div>
              <span className = 'mt-3'>...</span>
            </div>  
         </div>
      );
};

const PrepareDrawing = ({row,handlePublish})=>{
   
      return (
        <>
            {row.data.map((line,i)=>{
                 return (
                    <DrawingInput 
                       row = {line} 
                       key = {i} 
                       _id = {row._id}
                       handlePublish = {handlePublish}
                    />
                 )
            })}
        </>
    
      )
};

export default PrepareDrawing